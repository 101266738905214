import { useEffect, useRef, useState, useContext } from 'react';
import './members_certificate.css';
import { toPng } from 'html-to-image';
import moment from 'moment';
import back_foto from '.././../asset/certification-origin.jpg'
import { ua_months } from '../../services/local-data/months';
import { getDateFormat } from '../../helpers/date-format';
import { addMemberCertificateRequest, getData, updateUser, uploadFile } from '../../services/firebase-store.service';
import { Status } from '../../types/AdnimMenu';
import { AuthContext } from '../../core/auth';

const MembersCerificate = ({ date }: any) => {
    const [photo, setPhoto] = useState<any>(null);
    const [memberCertificate, setMemberCertificate] = useState<any>(null);
    const { user, changeUser, setLoading } = useContext(AuthContext);
    const _date = new Date(date).getDate()
    const _month = ua_months[new Date(date).getMonth()];
    const _year = new Date(date).getFullYear();

    moment.locale('ua');
    let upPhoto: any = null;
    const elementRef: any = useRef();

    useEffect(() => {
        if (user && user?.memberCertificateStatus == Status.approve) {
            getMemberCertificate()
        }
    }, [])

    const getMemberCertificate = async () => {
        const c = await getData(`${user?.profileId}`, 'memberCertificateRequest')
        setMemberCertificate(c);
    }

    // const htmlToImageConvertDownload = () => {
    //     toPng(elementRef.current, { cacheBust: true, fontEmbedCSS: '', })
    //         .then((dataUrl) => {
    //             const link = document.createElement("a");
    //             link.download = "certificate.png";
    //             link.href = dataUrl;
    //             link.click();
    //         })
    //         .catch((err) => console.log(err));
    // };

    const htmlToImageConvert = () => {
        setLoading(true);
        toPng(elementRef.current, { canvasWidth: 820, canvasHeight: 562, fontEmbedCSS: '', })
            .then((dataUrl) => {
                const cert = {
                    status: "pending",
                    data: dataUrl
                }
                addMemberCertificateRequest(cert, user)
                const _user = Object.assign({}, user)
                _user.memberCertificateStatus = Status.pending
                changeUser(_user);
                setLoading(false);
            })
            .catch((err) =>{
                 console.log(err);
                 setLoading(false);
                });
    };

    const preview = (file: any) => {
        const fr = new FileReader();
        fr.onload = () => {
            const img: any = document.createElement("img");
            img.src = fr.result;
            img.alt = file.name;
            if (document) {
                document.querySelector('#preview')?.append(img);
                setPhoto(img)
            }
        };
        fr.readAsDataURL(file);
    };

    const uploadImg = async (e: any) => {
        preview(e.target.files[0])
    }

    return (
        <div className='p-relative member-certificate'>

            <>
                {
                    user?.memberCertificateStatus == Status.pending
                        ? <div className='pending-status'>
                            <i className="fas fa-exclamation-circle"></i>
                            <p> Ваша заявка на посвідчення знаходиться на розгляді. Після підтвердження у Вас буде змога  скачати посвідчення </p>
                        </div>
                        : user?.memberCertificateStatus == Status.approve
                            ? memberCertificate &&
                            <div className='member-origin-cert'>
                            <p>Щоб скачати посвідчення клікнінь на нього ;)</p>
                            <a href={memberCertificate.data} download="Посвідчення"><img src={memberCertificate.data} alt="" /></a>
                            {/* <button className="btn btn-primary mt-3" onClick={() => htmlToImageConvertDownload()}>Завантажити посвідчення</button> */}
                            </div>
                            : user?.memberCertificateStatus == Status.cancel
                                ? <div className='pending-status'>
                                <i className="fas fa-exclamation-circle"></i>
                                <p> Ваша заявка на посвідчення відхилена </p>
                            </div>
                                : <>

                                    <div ref={elementRef} className="certificate-main my-3">
                                        <img src={back_foto} className="App-logo" alt="logo" />

                                        <div className='numOf'>{user?.profileId}  
                                        </div>
                                        <div className='member-cert-date'>від {_date} {_month} {_year} року</div>
                                        <div className="certificate-content">

                                            <div className='group-name'>
                                                <div className='image'>
                                                    <div className='image-cont' onClick={() => upPhoto?.click()}>
                                                        {!photo && <span>Click to upload img</span>}
                                                        <div id="preview"></div>
                                                    </div>
                                                    <input type="file" ref={(r) => upPhoto = r} id="photo" onChange={(e) => { uploadImg(e) }} />
                                                </div>
                                                <div className='name'>
                                                    {user?.lastName}
                                                    <br /> {user?.firstName} {user?.surName}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary mt-3" onClick={() => htmlToImageConvert()}>Згенерувати посвідчення</button>

                                </>





                }





            </>

        </div >

    );
}

export default MembersCerificate;