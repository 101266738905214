import './profile.css';
import { deleteFile, getCertificate, updateUser, uploadFile } from "../../services/firebase-store.service";
import { NotificationSvc } from "../../components/notification/Notification";
import { TabData, TypeData, User, UserTab } from "../../types/auth";
import { getComitetes } from "../../services/local-data/comitets";
import { getRegions } from "../../services/local-data/regions";
import { Modal_Confirm } from "../../components/modals/modal";
import { useContext, useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Comitet } from "../../types/Comitets";
import { AuthContext } from "../../core/auth";
import { PaymentTable } from '../../components/tables/paymentTable';
import { CertificateTable } from '../../components/tables/certificateTable';
import MembersCerificate from '../../components/members_certificate/members_certificate';

const tabs = () => {
  return [
    { name: 'Профіль' },
    { name: 'Оплати' },
    { name: 'Сертифікати' },
    { name: 'Посвідчення' },
  ]
}

const Profile = () => {
  const [textValueName, setTextValueName] = useState<string | null>(null);
  const { user, changeUser, setLoading } = useContext(AuthContext);
  const [textValue, setTextValue] = useState<string | null>(null);
  const [categoryName, setCategoryName] = useState<string>('');
  const [editID, setEditID] = useState<any>(null);
  const [_user, setUser] = useState<User>();
  const [certificates, setCertificates] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('Профіль');

  const regions = getRegions();
  const comitets = getComitetes();
  const { onSuccess } = NotificationSvc();
  let upPhoto: any = null;

  useEffect(() => setUser(user as User), [user]);
  useEffect(() => { window.scrollTo(0, 0) }, []);

  const OnAddclick = (id: string) => document.getElementById(id)?.click();
  const getExtension = (filename: string): any => filename.split('.').pop();

  const urlPatternValidation = () => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(textValue as string);
  };

  const saveData = async (e?: any) => {
    e?.preventDefault();
    if (user && _user) {
      await updateUser(user?.uid, _user);
      changeUser(_user);
      onSuccess('Profile was updated successfully ');
    }
  }

  const uploadImg = async (e: any) => {
    const file = e.target.files[0];
    if (user) {
      setLoading(true);
      await uploadFile(user, 'ava', file);
      setLoading(false);
      onSuccess('Photo was added successfully ');
    }
  }

  const onUploadFile = async (e: any, tab: UserTab, i: number, type: TypeData) => {
    if (user) {
      setLoading(true);
      const _user = Object.assign({}, user);
      if (type == TypeData.File) {
        const file = e.target.files[0];
        const newFile = await uploadFile(user, tab.name, file);
        type = getExtension(file.name).toLowerCase() === "pdf" ? TypeData.PDF : TypeData.File;
        _user.tabs[i].data.push({ type, value: newFile });
      }
      if (type == TypeData.Text && textValue) {
        if (editID != null) {
          _user.tabs[i].data[editID].value = textValue;
        } else {
          _user.tabs[i].data.push({ type, value: textValue });
        }
        setTextValue(null);
      }
      if (type == TypeData.Link && textValue && textValueName) {
        if (editID != null) {
          _user.tabs[i].data[editID].value = textValue;
          _user.tabs[i].data[editID].name = textValueName ? textValueName : textValue;
        } else {
          _user.tabs[i].data.push({ type, value: textValue, name: textValueName });
        }
        setTextValue(null);
        setTextValueName(null);
      }
      setUser(_user);
      saveData(e);
      setLoading(false);
      setEditID(null);
    }
  }

  const changeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, property: string) => {
    setUser({ ..._user, [property]: e.target.value } as User);
  }

  const showModalAddCat = (type: 'input' | 'text', isEdit: boolean, name: string = '', i: number = -1) => {
    const title = `${isEdit ? 'Edit' : 'New'} category`
    const text = ''
    Modal_Confirm(isEdit, i, confirm, text, title, name, type)
  }

  const confirm = (value: string, i: number, isEdit: boolean) => {
    value = value.trim().replaceAll(/\s+/g, '_');
    const u = _user as User;
    if (isEdit) {
      u.tabs[i].name = value;
    } else {
      const newCat: UserTab = { name: value, data: [], order: u.tabs.length + 1 };
      if (!(u.tabs)) {
        u.tabs = [];
      }
      u.tabs.push(newCat);
    }
    setUser(u);
    saveData();
    setUser(u);
    setCategoryName(value);
  }

  const OndeleteItem = (item: TabData, i: number, ii: number) => {
    Modal_Confirm(false, i, () => deleteItem(item, i, ii), 'Ви впевнені що хочете видалити цей елемент ?', 'Confirm', item, 'text');
  }

  const deleteItem = (item: TabData, i: number, ii: number) => {
    setLoading(true);
    const _user = Object.assign({}, user);
    _user.tabs[i].data.splice(ii, 1);
    if (item.type == TypeData.File) {
      deleteFile(item.value);
    }
    setUser(_user);
    saveData();
    setLoading(false);
  }

  const deleteTab = (tab: UserTab, i: number) => {
    setLoading(true);
    tab.data.map((el: TabData) => {
      if (el.type == TypeData.File) {
        deleteFile(el.value);
      }
    })
    const _user = Object.assign({}, user);
    _user.tabs.splice(i, 1);
    setUser(_user);
    saveData();
    setLoading(false);
  }

  const OnDeleteTab = (tab: UserTab, i: number) => {
    Modal_Confirm(false, i, deleteTab, `Ви впевнені що хочете видалити "${tab.name}" категорію?`, 'Confirm', tab, 'text');
  }

  const onEditing = (item: TabData, i: number, ii: number) => {
    if (item.name) {
      setTextValueName(item.name);
    }
    setTextValue(item.value);
    setEditID(ii);
  }

  const onDragOver = (ev: React.FormEvent<EventTarget>): void => ev.preventDefault();
  const onDragStart = (ev: any, i: number): void => ev.dataTransfer.setData("index", i);

  const onDrop = (ev: any, i: number): void => {
    ev.preventDefault();
    const getIndex = ev.dataTransfer.getData("index");
    const user = Object.assign({}, _user);
    const newData = reorder(user.tabs, getIndex, i)
    newData.map((tab: UserTab, i: number) => tab.order = i)
    user.tabs = newData

    setUser(user);
    saveData();
    setUser(user);
  }

  const reorder = (list: UserTab[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const clickTab = async (el: any) => {
    if (el.name == 'Сертифікати') {
      const a = await getCertificate(user?.uid)
      if (a?.certificates)
        setCertificates(a.certificates);
    }
    setActiveTab(el.name);
  }

  const changeContacts = (property: string, value: any = '', isCheck: boolean = false) => {
    const user = Object.assign({}, _user);
    if (!user.contacts) {
      user.contacts = [];
    }
    const inx = user.contacts.findIndex(el => el.name == property);
    if (inx >= 0) {
      isCheck
        ? user.contacts[inx].checked = !user.contacts[inx].checked
        : user.contacts[inx].value = value

    } else {
      const _contact = { name: property, value: null, checked: false }
      isCheck
        ? _contact.checked = !_contact.checked
        : _contact.value = value
      user.contacts.push(_contact);
    }

    setUser(user);
  }


  return (
    <div className="mx-4 pt-5 profile">
      <div className="content">
        <ul className="nav nav-tabs">
          {
            tabs().map((el: any, i: number) =>
              <li className="nav-item" key={i} onClick={() => clickTab(el)}>
                <div className={`nav-link ${el.name == activeTab ? 'active' : ''}`} aria-current="page" >{el.name}</div>
              </li>

            )
          }
        </ul>

        {
          activeTab == tabs()[0].name
            ? <>
              <h3 className='my-5'>Профіль</h3>

              <form className='needs-validation' onSubmit={(e) => saveData(e)} >
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Прізвище</span>
                        <input type="text"
                          required
                          value={_user?.lastName || ''}
                          className="form-control"
                          placeholder="Прізвище"
                          aria-label="lastName"
                          aria-describedby="basic-addon1"
                          onChange={(e) => changeValue(e, 'lastName')}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Ім'я</span>
                        <input type="text"
                          required
                          value={_user?.firstName || ''}
                          className="form-control"
                          placeholder="Ім'я"
                          aria-label="firstName"
                          aria-describedby="basic-addon1"
                          onChange={(e) => changeValue(e, 'firstName')}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">По Батькові</span>
                        <input type="text"
                          required
                          value={_user?.surName || ''}
                          className="form-control"
                          placeholder="По батькові"
                          aria-label="surName"
                          aria-describedby="basic-addon1"
                          onChange={(e) => changeValue(e, 'surName')}
                        />
                      </div>

                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Дата <br /> народження</span>
                        <input type="date"
                          required
                          value={_user?.dateOfBirth || ''}
                          className="form-control"
                          placeholder="dateOfBirth"
                          aria-label="dateOfBirth"
                          aria-describedby="basic-addon1"
                          onChange={(e) => changeValue(e, 'dateOfBirth')}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Область</span>
                        <select value={_user?.region || ''} id="region" required className="form-select" onChange={e => changeValue(e, 'region')} aria-label="Default select example">
                          <option className="mystyle" hidden value={''} ></option>
                          {
                            regions.map((el: any, i: number) => <option key={i} className="mystyle" value={el.name}>{el.name}</option>)
                          }
                        </select>
                      </div>

                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Email</span>
                        <input type="text"
                          required
                          value={_user?.email || ''}
                          className="form-control"
                          placeholder="email"
                          aria-label="email"
                          aria-describedby="basic-addon1"
                          onChange={(e) => changeValue(e, 'email')}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="input-group mb-3 ava">
                        <span onClick={() => upPhoto?.click()} title="Change photo">
                          {
                            _user?.photoURL
                              ? <img style={{ width: '200px' }} src={`${_user?.photoURL}`}></img>
                              : <i style={{ fontSize: '140px' }} className="far fa-user"> </i>
                          }
                          <input type="file" ref={(r) => upPhoto = r} id="photo" onChange={(e) => { uploadImg(e) }} />
                        </span>
                      </div>
                    </div>

                  </div>
                  {/* _______row */}

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">Місце роботи</span>
                          <input type="text"
                            required
                            value={_user?.workPlace || ''}
                            className="form-control"
                            placeholder="Місце роботи"
                            aria-label="workPlace"
                            aria-describedby="basic-addon1"
                            onChange={(e) => changeValue(e, 'workPlace')}
                          />
                        </div>

                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">Посада</span>
                          <input type="text"
                            required
                            value={_user?.position || ''}
                            className="form-control"
                            placeholder="Посада"
                            aria-label="position"
                            aria-describedby="basic-addon1"
                            onChange={(e) => changeValue(e, 'position')}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1"> Центральний <br /> комітет </span>
                          <select id="centralComm" required className="form-select" onChange={e => changeValue(e, 'centralComm')} value={_user?.centralComm || ''} aria-label="Default select example">
                            <option className="mystyle" hidden value={''} ></option>
                            {
                              comitets.map((comitet: Comitet, i: number) =>
                                <option key={i} className="mystyle" value={comitet.value}>{comitet.name}</option>
                              )
                            }
                          </select>
                        </div>

                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">Номер <br /> координатора</span>
                          <input type="text"
                            value={_user?.coordinatorNumber || ''}
                            className="form-control"
                            placeholder="Номер координатора"
                            aria-label="coordinatorNumber"
                            aria-describedby="basic-addon1"
                            onChange={(e) => changeValue(e, 'coordinatorNumber')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <h4 className="text-center mt-3">Контакти (публічні)</h4>
                      <div className="row py-4">
                        <div className="col-md-6 col-sm-12">
                          <div className="input-group mb-3">
                            <div className='display-f pt-2'>
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                value="categoryType"
                                id="registerCheck"
                                checked={_user?.contacts && _user?.contacts.find(e => e.name == 'email')?.checked}
                                onChange={(e) => changeContacts('email', '', true)}
                                aria-describedby="registerCheckHelpText"
                              />
                            </div>
                            <span className="input-group-text" id="basic-addon1">Email</span>
                            <input type="text"
                              value={_user?.contacts && _user?.contacts.find(e => e.name == 'email')?.value || ''}
                              className="form-control"
                              placeholder="Email"
                              aria-label="email"
                              aria-describedby="basic-addon1"
                              onChange={(e) => changeContacts('email', e.target.value)}
                            />
                          </div>
                          <div className="input-group mb-3">
                            <div className='display-f pt-2'>
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                value="categoryType"
                                id="registerCheck"
                                checked={_user?.contacts && _user?.contacts.find(e => e.name == 'phone')?.checked}
                                onChange={(e) => changeContacts('phone', '', true)}
                                aria-describedby="registerCheckHelpText"
                              />
                            </div>

                            <span className="input-group-text" id="basic-addon1">Телефон</span>
                            <input type="number"
                              value={_user?.contacts && _user?.contacts.find(e => e.name == 'phone')?.value || ''}
                              className="form-control"
                              placeholder="Телефон"
                              aria-label="phoneNumber"
                              aria-describedby="basic-addon1"
                              onChange={(e) => changeContacts('phone', e.target.value)}
                            />
                          </div>

                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="input-group mb-3">
                            <div className='display-f pt-2'>
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                value="categoryType"
                                id="registerCheck"
                                checked={_user?.contacts && _user?.contacts.find(e => e.name == 'facebook')?.checked}
                                onChange={(e) => changeContacts('facebook', '', true)}
                                aria-describedby="registerCheckHelpText"
                              />
                            </div>
                            <span className="input-group-text" id="basic-addon1">Facebook</span>
                            <input type="url"
                              value={_user?.contacts && _user?.contacts.find(e => e.name == 'facebook')?.value || ''}
                              className="form-control"
                              placeholder="facebook"
                              aria-label="facebook"
                              aria-describedby="basic-addon1"
                              onChange={(e) => changeContacts('facebook', e.target.value)}
                            />
                          </div>
                          <div className="input-group mb-3">
                            <div className='display-f pt-2'>
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                value="categoryType"
                                id="registerCheck"
                                checked={_user?.contacts && _user?.contacts.find(e => e.name == 'instagram')?.checked}
                                onChange={(e) => changeContacts('instagram', '', true)}
                                aria-describedby="registerCheckHelpText"
                              />
                            </div>
                            <span className="input-group-text" id="basic-addon1">Instagram</span>
                            <input type="url"
                              value={_user?.contacts && _user?.contacts.find(e => e.name == 'instagram')?.value || ''}
                              className="form-control"
                              placeholder="Instagram"
                              aria-label="phoneNumber"
                              aria-describedby="basic-addon1"
                              onChange={(e) => changeContacts('instagram', e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="alert alert-success" role="alert">
  *   Поставте галочку перед контактом для відображення його в публічному профілі
</div>
                    </div>


                    <div className="text-right">
                      <button type="submit" className="btn btn-primary my-3 m-5"> Зберегти </button>
                    </div>
                  </div>
                </div>
              </form>


              <div className="container">
                <h4 className="text-center mb-4">Категорії</h4>

                <div className="add-button">

                  <div onClick={() => showModalAddCat('input', false)} className="btn text-white" style={{ backgroundColor: '#3b5998' }} role="button">
                    <i className="fas fa-plus-square" /> <span > Додати категорію </span>
                  </div>
                </div>

                <div className="accordion accordion-flush profile" id="accordionCategories"
                  onDragOver={(e) => onDragOver(e)}
                  onDrop={(e) => { }}
                >
                  {_user?.tabs?.sort((a: any, b: any) => (a.order > b.order ? 1 : -1)).map((tab: UserTab, i: number) =>
                    <div className="accordion-item" key={i}
                      onDragStart={(e) => onDragStart(e, i)}
                      onDrop={(e) => { onDrop(e, i) }}
                      draggable
                    >

                      <i className='fas fa-hand-pointer  move-icon'>&#xf338;</i>
                      <div className="edit-btns">
                        <i className="fas fa-edit" onClick={() => showModalAddCat('input', true, tab.name, i)} title="Edit" />
                        <i className="fas fa-trash del-icon" onClick={() => OnDeleteTab(tab, i)} title="Delete" />
                      </div>

                      <h2 className="accordion-header" id={`flush-heading-${i}`}>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse-${i}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse-${i}`}
                          onClick={() => { setEditID(null); setTextValue(null); setTextValueName(null) }}
                        >
                          {tab.name.replaceAll('_', ' ')}
                        </button>
                      </h2>
                      <div id={`flush-collapse-${i}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`flush-heading-${i}`}
                        data-bs-parent="#accordionCategories"
                      >
                        <div className="accordion-body">
                          <div className="row my-1 under-line">
                            {
                              tab.data.map((el: TabData, ii: number) => {
                                return el.value ?
                                  <div key={ii} className="text-center my-1 item-category">
                                    {
                                      el.type == TypeData.File
                                        ? <img style={{ width: '90%', maxWidth: '400px' }} src={`${el.value}`}></img>
                                        : el.type == TypeData.PDF
                                          ? <object data={el.value} type="application/pdf" width="90%" height="850px"> </object>
                                          : el.type == TypeData.Text
                                            ? <div>{editID == ii ?
                                              <>
                                                <div className="input-group mb-3">
                                                  <textarea
                                                    required
                                                    rows={5}
                                                    value={textValue || ''}
                                                    className="form-control"
                                                    aria-describedby="basic-addon1"
                                                    onChange={e => setTextValue(e.target.value)}
                                                  />
                                                </div>
                                                <div className="text-right">
                                                  <button onClick={(e) => { setTextValue(null); setEditID(null) }} className={`btn btn-danger my-3 m-1`}> Відміна </button>
                                                  <button onClick={(e) => { onUploadFile(e, tab, i, TypeData.Text) }} className="btn btn-primary my-3 "> Зберегти </button>
                                                </div>
                                              </>
                                              : <pre> {el.value}</pre>
                                            }
                                            </div>
                                            : editID == ii
                                              ? <div>
                                                <div className="input-group mb-3">
                                                  <span className="input-group-text" id="basic-addon1">Адреса</span>
                                                  <input type="url"
                                                    required
                                                    value={textValue || ''}
                                                    className={`form-control ${urlPatternValidation() ? '' : 'invalid-input'}`}
                                                    placeholder="https://example.com"
                                                    aria-describedby="basic-addon1"
                                                    onChange={e => setTextValue(e.target.value)}
                                                  />
                                                </div>
                                                <div className="input-group mb-3">
                                                  <span className="input-group-text" id="basic-addon2">Назва</span>
                                                  <input type="text"
                                                    value={textValueName || ''}
                                                    className="form-control"
                                                    placeholder="name"
                                                    aria-describedby="basic-addon2"
                                                    onChange={e => setTextValueName(e.target.value)}
                                                  />
                                                </div>
                                                {
                                                  <div className="text-right">
                                                    <button onClick={(e) => { setTextValue(null); setTextValueName(null); setEditID(null) }} className={`btn btn-danger my-3 m-1`}> Відміна </button>
                                                    <button onClick={(e) => { onUploadFile(e, tab, i, TypeData.Link) }} className={`btn btn-primary my-3  ${urlPatternValidation() ? '' : 'disabled'}`}> Зберегти </button>
                                                  </div>
                                                }
                                              </div>
                                              : <a target="_blank" href={el.value}>{el.name ? el.name : el.value}</a>
                                    }
                                    {
                                      editID !== ii &&
                                      <div className="edit-btns">
                                        {
                                          el.type !== TypeData.File && el.type !== TypeData.PDF &&
                                          <i className="fas fa-edit" onClick={() => onEditing(el, i, ii)} title="Edit" />
                                        }
                                        <i className="fas fa-trash del-icon" onClick={() => OndeleteItem(el, i, ii)} title="Delete" />
                                      </div>
                                    }
                                  </div>
                                  : null

                              }
                              )
                            }
                            {
                              textValueName !== null && editID == null
                                ? <div>
                                  <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Адреса</span>
                                    <input type="url"
                                      required
                                      value={textValue || ''}
                                      className={`form-control ${urlPatternValidation() ? '' : 'invalid-input'}`}
                                      placeholder="https://example.com"
                                      aria-describedby="basic-addon1"
                                      onChange={e => setTextValue(e.target.value)}
                                    />
                                  </div>
                                  <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon2">Назва</span>
                                    <input type="text"
                                      value={textValueName || ''}
                                      className="form-control"
                                      placeholder="name"
                                      aria-describedby="basic-addon2"
                                      onChange={e => setTextValueName(e.target.value)}
                                    />
                                  </div>
                                  {
                                    <div className="text-right">
                                      <button onClick={(e) => { setTextValue(null); setTextValueName(null) }} className={`btn btn-danger my-3 m-1`}> Відміна </button>
                                      <button onClick={(e) => { onUploadFile(e, tab, i, TypeData.Link) }} className={`btn btn-primary my-3  ${urlPatternValidation() ? '' : 'disabled'}`}> Зберегти </button>
                                    </div>
                                  }
                                </div>

                                : textValue !== null && editID == null
                                && <>
                                  <div className="input-group mb-3">
                                    <textarea
                                      required
                                      rows={5}
                                      value={textValue || ''}
                                      className="form-control"
                                      aria-describedby="basic-addon1"
                                      onChange={e => setTextValue(e.target.value)}
                                    />
                                  </div>
                                  <div className="text-right">
                                    <button onClick={(e) => { setTextValue(null); setEditID(null) }} className={`btn btn-danger my-3 m-1`}> Відміна </button>
                                    <button onClick={(e) => { onUploadFile(e, tab, i, TypeData.Text) }} className="btn btn-primary my-3 "> Зберегти </button>
                                  </div>
                                </>
                            }
                          </div>
                          <div >
                            <div className="mt-3 cat-group-btns">
                              <button
                                onClick={() => OnAddclick(`file${i}`)}
                                type="button" className="btn btn-outline-primary " data-mdb-ripple-color="dark">
                                Додати фото
                                <input accept="image/png, image/jpeg, application/pdf" type="file" id={`file${i}`} onChange={(e) => { onUploadFile(e, tab, i, TypeData.File) }} />
                              </button>
                              <button onClick={() => setTextValue('')} type="button" className="btn btn-outline-success" data-mdb-ripple-color="dark">
                                Додати текст
                              </button>
                              <button onClick={() => { setTextValue(''); setTextValueName('') }} type="button" className="btn btn-outline-info " data-mdb-ripple-color="dark">
                                Додати посилання
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  }

                </div>
              </div>


              <div className="container">

                <div className="row my-5">
                  {
                    user && <NavLink to={`/user/${user?.profileId}`} >
                      <button type="button" className="btn btn-light">
                        View Profile page
                      </button>
                    </NavLink>
                  }

                </div>
              </div>
            </>
            : activeTab == tabs()[1].name
              ? <div className='payments'>
                <PaymentTable payments={user?.payments} />
              </div>
              : activeTab == tabs()[2].name
                ? <div className='mb-5'>
                  <CertificateTable certificates={certificates} />
                </div>
                : activeTab == tabs()[3].name
                  ? <div>
                    <MembersCerificate user={user} date={user?.create} />
                  </div>
                  : null

        }




      </div>
    </div>
  )
}

export default withRouter(Profile);
