import { useContext, useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AuthContext } from "../../../core/auth";
import { NavLink } from "react-router-dom";
import { getUsers, getUsersData } from "../../../services/firebase-store.service";
import { User } from "../../../types/auth";
import { getComitetById } from "../../../services/local-data/comitets";
import { getDateFormat } from "../../../helpers/date-format";
import { DataService } from "../../../services/firebase.service";
import { NotificationSvc } from "../../../components/notification/Notification";
import { setSendedEmail } from "../../../services/firebase-admin.service";

export const SendEmailsAdmin = (props: RouteComponentProps) => {
    const [users, setUsers] = useState<[]>([]);
    const { user, setLoading } = useContext(AuthContext);
    const [message, setMessage] = useState<any>('');
    const [listOfEmails, setListOfEmails] = useState<any>('');
    const [showList, setShowList] = useState<boolean>(false);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    const [theme, setTheme] = useState<string>('');
    const { sendEmail } = DataService();
    const { onSuccess } = NotificationSvc();


    useEffect(() => { window.scrollTo(0, 0) }, []);

    useEffect(() => {
        user?.role == 'publisher' && getData();
    }, [user])

    const getData = async () => {
        setLoading(true);
        const _users = await getUsersData();
        setUsers(_users.filter((x: User) => x.uid));
        setLoading(false);
    }

    const sendMessage = async () => {
        setLoading(true);
        await sendEmail(
            '',
            listOfEmails.slice(0, -1),
            theme,
            '',
            '',
            '',
            message
        ).then(() => {
            onSuccess('Повідомлення відправлено');
            setListOfEmails('');
            setTheme('');
            setMessage('');
        });
        setLoading(false);
        setSendedEmail(listOfEmails.slice(0, -1), theme, message);

    }

    const selectUser = (user: User, i: number) => {
        const _users: any = Object.assign([], users);
        _users[i].checked = !_users[i].checked;
        setUsers(_users);
    }

    const selectedEmails = (e: any) => {
        e.stopPropagation();
        let str = '';
        users.map((u: any) => {
            if (u.checked) {
                str += `${u.email},`
            }
        })
        setListOfEmails(str);
        setShowList(false);
    }

    const selectAll = () => {
        const _users: any = users.map((u: any) => {
            u.checked = !checkedAll;
            return u;
        })
        setUsers(_users);
        setCheckedAll(!checkedAll);
    }

    const addNewEmail = () => {
        const _users: any = Object.assign([], users);
        _users.push({ email: '', checked: true });
        setUsers(_users);
    }

    const changeEmail = (e: any, i: number) => {
        const _users: any = Object.assign([], users)
        _users[i].email = e.target.value;
        setUsers(_users);
    }

    const isValidMessage = (): boolean => {
        return !(message.length > 0 && theme.length > 0 && listOfEmails.length > 1);
    }

    const renderList = () => {

        return (
            <div>
                <table id="small-table" className="table-sm">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Email</th>
                            <th scope="col">ПІБ</th>
                            <th scope="col" className="text-center" onClick={() => selectAll()}>
                                Усі
                                {<i className={`p-2 ${checkedAll ? 'fas fa-check-square' : 'far fa-square'}`} />}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((user: any, i: number) =>
                                <tr key={i} onClick={() => selectUser(user, i)}>
                                    <th scope="row">{i + 1}</th>
                                    <td>
                                        {
                                            user.uid
                                                ? user.email
                                                : <input type="email" value={user.email} onChange={(e) => changeEmail(e, i)} onClick={(e) => e.stopPropagation()} />
                                        }
                                    </td>
                                    <td>{user.lastName} {user.firstName} {user.surName}</td>
                                    <td className="text-center">
                                        {<i className={`${user.checked ? 'fas fa-check-square' : 'far fa-square'}`} />}
                                    </td>
                                </tr>
                            )
                        }
                        <tr>
                            <i onClick={() => addNewEmail()} className="p-2 fas fa-plus"></i>
                        </tr>
                    </tbody>
                </table>
                <div className="text-right p-2">
                    <button className="btn btn-sm btn-primary" onClick={(e) => selectedEmails(e)}>OK</button>
                </div>
            </div>
        )
    }


    return (
        <div className="p-2">
            <h3>Повідомлення</h3>

            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Кому</span>
                <div className="form-control" onClick={() => setShowList(true)}>
                    {
                        showList
                            ? renderList()
                            : <div className="list-emails">{listOfEmails}</div>
                    }
                </div>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Тема</span>
                <div className="form-control">
                    <input value={theme} onChange={(e) => setTheme(e.target.value)} className="form-control" type="text" />
                </div>
            </div>
            <textarea
                rows={10}
                value={message}
                className="form-control"
                placeholder="Повідомлення ..."
                onChange={(e) => setMessage(e.target.value)}
            />
            <div className="p-5 mb-3 text-right">
                <button onClick={() => sendMessage()} className="btn btn-danger" disabled={isValidMessage()}>Відправити</button>
            </div>
        </div>
    )
}

export default withRouter(SendEmailsAdmin);