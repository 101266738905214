import { useContext, useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AuthContext } from "../../../core/auth";
import { getUsersData } from "../../../services/firebase-store.service";
import { User } from "../../../types/auth";
import { getDateFormat } from "../../../helpers/date-format";

export const Testing = (props: RouteComponentProps) => {
    const [data, setData] = useState<any>([]);
    const { user, setLoading } = useContext(AuthContext);

    useEffect(() => {
        user && getData();
    }, [user])

    const getData = async () => {
        setLoading(true);
        let _users = await getUsersData();
        _users = _users.filter((user: User, i: number) => user.tests?.find((x: any) => x.testId));
        setData(_users);
        setLoading(false);
    }

    return (
        <div className="p-2">
            <h3>Тестування</h3>

            <table className="table table-dark table-striped apll-table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th className="text-center" >ПІБ</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((user: User, i: number) =>
                            <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>
                                    {user.lastName} {user.firstName} {user.surName}
                                    < br/> <span className="s-title"> {user.email}</span>
                                </td>
                                <td>
                                    <table className="text-center table table-bordered text-white  apll-table">
                                        <th> Тест</th>
                                        <th> Бали</th>
                                        <th> Дата</th>
                                        <th> Статус</th>
                                        <tbody>
                                            {
                                                user.tests.map((t: any, ii: number) =>
                                                    <tr key={ii}>
                                                        <td>{t.testId}</td>
                                                        <td>{t.result}</td>
                                                        <td>{t.date ? getDateFormat(t.date) : ''}</td>
                                                        <td className="status-td"><i className={`${t.result + 40 >= 70 ? 'fas fa-thumbs-up' : 'fas fa-thumbs-down red' }`}/></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}

export default withRouter(Testing);