type TMakeRequestProps = {
    method?: string;
    body?: any;
    id?: string
    queryUrl?: string
}


export const UseFetch = <T,>(baseUrl?: string) => {

    const _makeRequest = async (params: TMakeRequestProps) => {
      return  fetch(`${baseUrl}`, {
            method: "POST",
            body: params.body ? JSON.stringify(params.body) : undefined
            }).then(response => response)
            .then((data: any) => data)
            .catch((error) => {
              console.error('Error:', error);
            });
    }

    const makeRequest = async (params: TMakeRequestProps) => {
        
        const requestOptions = {
            method: params.method ? params.method : "GET",
            headers: {
                "Content-Type": "*"
            },
            body: params.body ? JSON.stringify(params.body) : undefined
        }
        try {
            const response = await fetch(`${baseUrl}${params.id ? params.id : ''}`, requestOptions)
            return await response.json();
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
   

    return {
        makeRequest, _makeRequest
    }
}

