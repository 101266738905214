import { Kyrse } from "../../types/Kyrse"

const Kyrses: Kyrse[] = [
    {
        id: 'ЗК.2024.001',
        displayId: 'ЗК.2023.001',
        haveTest: true,
        durationHours: 30,
        durationDays: 6,
        credits: 1,
        price: 650,
        priceMembers: 650,
        themes: ['Тема 1. Стратегічна роль євроатлантичної інтеграції та її інформаційне поле в сучасному публічному управлінні України', 'Тема 2. Співробітництво України з НАТО: перспективи розвитку інформаційної взаємодії та комунікації', 'Тема 3. Основи інформаційної взаємодії (комунікації) з питань євроатлантичної інтеграції', 'Тема 4. Перспективи розвитку системної інформаційної взаємодії (комунікації) з питань євроатлантичної інтеграції'],
        docs: [{ name: 'Презентація', value: 'https://firebasestorage.googleapis.com/v0/b/web-unapam.appspot.com/o/programs%2F1%2F%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F_001.pptx?alt=media&token=f8175651-1462-4fa4-9a38-dfd96de8e607&_gl=1*fhnel3*_ga*NDA1NjQwNTQyLjE2OTMyMTM5MDQ.*_ga_CW55HF8NVT*MTY5OTQzNzUwNi44NC4xLjE2OTk0MzgwOTYuNDUuMC4w' }],
        files: [],
        name: '«Євроатлантична інтеграція та інформаційна взаємодія (комунікація) в Україні» (Погоджено наказом Національного агентства України з питань державної служби від 08 лютого 2024 р. № 19-24 «Про погодження програм підвищення кваліфікації») ',
        value: <div className="kyrs-info my-5">
            <h5 className="text-center mt-5">ПРОФІЛЬ ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={2}>1. Загальна інформація</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Назва програми  </td>
                        <td>Євроатлантична інтеграція та інформаційна взаємодія (комунікація) в Україні  </td>
                    </tr>
                    <tr>
                        <td>Шифр програми</td>
                        <td>ЗК/2024/001 </td>
                    </tr>
                    <tr>
                        <td>Тип програми за змістом</td>
                        <td>загальна короткострокова програма підвищення кваліфікації</td>
                    </tr>
                    <tr>
                        <td>Форма навчання</td>
                        <td>дистанційна в асинхронному режимі</td>
                    </tr>
                    <tr>
                        <td>Цільова група</td>
                        <td>державні службовці, які займають посади державної служби категорій «Б» та «В», посадові особи місцевого самоврядування та фахівці, які забезпечують планування, координацію, проведення моніторингу та іншу професійну діяльність у сфері євроатлантичної інтеграції та інформаційної взаємодії (комунікації) в Україні </td>
                    </tr>
                    <tr>
                        <td>Передумови навчання за програмою</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування замовника освітніх послуг у сфері професійного навчання за програмою-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування партнера (партнерів) програми</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Обсяг програми</td>
                        <td>1 кредит ЄКТС</td>
                    </tr>
                    <tr>
                        <td>Тривалість програми та організація навчання</td>
                        <td>загальна тривалість програми: 6 днів</td>
                    </tr>
                    <tr>
                        <td>Мова(и) викладання</td>
                        <td>українська</td>
                    </tr>
                    <tr>
                        <td>Напрям(и) підвищення кваліфікації, який (які) охоплює програма</td>
                        <td>Євроатлантична інтеграція та інформаційна взаємодія (комунікація) в Україні</td>
                    </tr>
                    <tr>
                        <td>Перелік професійних компетентностей, на підвищення рівня яких спрямовано програму </td>
                        <td>професійні знання про політику у сфері євроатлантичної інтеграції комунікація та взаємодія  </td>
                    </tr>
                    <tr>
                        <td>Укладач(і) програми</td>
                        <td>Карпа Марта Іванівна, професор, доктор наук з державного управління, професор кафедри публічного управління та адміністрування Університету Григорія Сковороди в Переяславі, E-mail: marta.karpa@ukr.net</td>
                    </tr>

                    <tr>
                        <th className="text-center" colSpan={2}>2. Загальна мета</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Підвищення професійних компетентностей державних службовців та посадових осіб місцевого самоврядування, які забезпечують планування, координацію, моніторинг, інформаційну комунікацію та іншу професійну діяльність у сфері євроатлантичної інтеграції та інформаційної взаємодії (комунікації) в Україні</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>3. Очікувані результати навчання</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>За результатами навчання слухачі повинні демонструвати:</td>
                    </tr>
                    <tr>
                        <td>знання</td>
                        <td>про співробітництво України у сфері євроатлантичної інтеграції та інформаційної взаємодії (комунікації); правових та організаційних засад євроатлантичної інтеграції та інформаційної взаємодії (комунікації) в Україні; основ інформаційної взаємодії (комунікації) з питань євроатлантичної інтеграції</td>
                    </tr>
                    <tr>
                        <td>уміння</td>
                        <td>ефективної комунікації з питань євроатлантичної інтеграції; підтримувати міжвідомчу комунікацію з питань євроатлантичної інтеграції; співпраці між органами публічної влади та громадськістю з питань євроатлантичної інтеграції; сприяти формуванню позиції громадян України щодо євроатлантичної інтеграції; аналізувати річні національні програми під егідою Комісії Україна-НАТО</td>
                    </tr>
                    <tr>
                        <td>навички</td>
                        <td>здійснення та застосування інформаційної взаємодії (комунікації) з іншими органами публічної влади та громадськістю з питань євроатлантичної інтеграції; приймати зміни та змінюватись відповідно до державної політики євроатлантичної інтеграції</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>4. Викладання та навчання (методи навчання, форми проведення навчальних занять)</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Дистанційне навчання через доступ до навчальних матеріалів через вебсередовище в асинхронному режимі (режимі відкладеного часу) із застосуванням електронної пошти; самостійне опрацювання рекомендованої літератури, інформаційних ресурсів. Методи навчання – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований. </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>5. Ресурсне забезпечення дистанційного навчання</th>
                    </tr>
                    <tr>
                        <td>Назви вебплатформи, вебсайту, електронної системи навчання, через які здійснюватиметься дистанційне навчання із зазначенням посилання (вебадреси)</td>
                        <td>Вебадреса: www.unapam.com <br />
                            електронна пошта: officeUNAPAM@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Назва дистанційного етапу/модуля</td>
                        <td>Тема 1 . Стратегічна роль євроатлантичної інтеграції та її інформаційне поле в сучасному публічному управлінні України
                            <br />  Тема 2 . Співробітництво України з НАТО: перспективи розвитку інформаційної взаємодії та комунікації
                            <br /> Тема 3. Основи інформаційної взаємодії (комунікації) з питань євроатлантичної інтеграції
                            <br /> Тема 4. Перспективи розвитку системної інформаційної взаємодії (комунікації) з питань євроатлантичної інтеграції</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>6. Оцінювання і форми поточного, підсумкового контролю</th>
                    </tr>
                    <tr>
                        <td>Складові оцінювання та їх питома вага у підсумковій оцінці (%)</td>
                        <td>Відвідування занять у дистанційному форматі – 40%
                            Опрацювання обов’язкової літератури, інформаційних та інших матеріалів – 10%
                            Підсумковий контроль – 50%
                            Документ про підвищення кваліфікації видається за умови набрання учасником професійного навчання не менше ніж 70%, обрахованих з урахуванням питомої ваги кожного із критеріїв оцінювання.</td>
                    </tr>
                    <tr>
                        <td>Форма підсумкового контролю</td>
                        <td>комп’ютерне тестування</td>
                    </tr>
                </tbody>
            </table>


            <h5 className="text-center mt-5">СТРУКТУРА ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle table-responsive">
                <thead>
                    <tr>
                        <th className="text-center" rowSpan={3} >Назва теми </th>
                        <th colSpan={6}>Кількість годин </th>
                    </tr>
                    <tr>
                        <th className="text-center" rowSpan={2} >загальна кількість годин/ кредитів ЄКТС</th>
                        <th className="text-center" colSpan={6}>у тому числі: </th>

                    </tr>
                    <tr>
                        <th className="text-center" >аудиторні заняття</th>
                        <th className="text-center" >дистанційні заняття </th>
                        <th className="text-center" >навчальні візити </th>
                        <th className="text-center" >самостійна робота слухачів </th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                    </tr>
                    <tr>
                        <td>Тема 1 < br />
                            Стратегічна роль
                            євроатлантичної інтеграції
                            та її інформаційне поле в
                            сучасному публічному
                            управлінні України
                        </td>
                        <td>6/0,20</td>
                        <td></td>
                        <td>4</td>
                        <td></td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Тема 2 <br />
                            Співробітництво України з
                            НАТО: перспективи
                            розвитку інформаційної
                            взаємодії та комунікації
                        </td>
                        <td>8/0,27</td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Тема 3 <br />
                            Основи інформаційної
                            взаємодії (комунікації) з
                            питань євроатлантичної
                            інтеграції
                        </td>
                        <td>7/0,23</td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Тема 4 <br />
                            Перспективи розвитку
                            системної інформаційної
                            взаємодії (комунікації) з
                            питань євроатлантичної
                            інтеграції
                        </td>
                        <td>7/0,23</td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Підсумковий контроль результатів навчання</td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <th> РАЗОМ </th>
                        <th>30/1,00</th>
                        <th></th>
                        <th>24</th>
                        <th></th>
                        <th>6</th>
                    </tr>
                </tbody>
            </table>


            <h5 className="text-center mt-5">ЗМІСТ ПРОГРАМИ</h5>
            <p><b>Тема 1. Стратегічна роль євроатлантичної інтеграції та її інформаційне поле в сучасному публічному управлінні України </b>
                <br /> <b>Перелік питань для вивчення: </b>
                <br />Передумови та нормативно-правове підґрунтя формування та реалізації інформаційної взаємодії між органами публічної влади щодо євроатлантичної інтеграції
                <br />Євроатлантична інтеграція та інституціоналізація відносин з країнами
                <br />Актуальні питання особливого партнерства України з НАТО
                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Ознайомлення з міжнародними договорами між Україною та НАТО та уповноваженими органами НАТО
            </p>
            <br />
            <p>
                <b>Тема 2. Співробітництво України з НАТО: перспективи розвитку інформаційної взаємодії та комунікації.
                    <br /> Перелік питань для вивчення: </b>
                <br /> Річні національні програми під егідою Комісії Україна — НАТО: мета реформ, цілі, очікувані результати та пріоритетні завдання. Особливості розробки та виконання Річних національних програм під егідою Комісії Україна – НАТО
                <br />Аналіз інформації про виконання заходів Річної національної програми під егідою Комісії Україна – НАТО
                <br /> Інструмент моніторингу та управління реалізацією стратегії за міжнародною методологією RBM (англ. Results-Based Management) "Управління, орієнтоване на результат"
                <br />Особливості реформ, які необхідно впровадити в Україні з метою досягнення критеріїв членства в НАТО
                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Аналіз Положення про річні національні програми під егідою Комісії Україна – НАТО (Указ Президента України від 24.02.2021 № 72/2021)
            </p>
            <br />
            <p>
                <b>Тема 3. Основи інформаційної взаємодії (комунікації) з питань євроатлантичної інтеграції
                    <br />Перелік питань для вивчення: </b>
                <br />Міжвідомча комунікація з питань євроатлантичної інтеграції між центральними та місцевими органами виконавчої влади та місцевого самоврядування
                <br />Взаємодія органів публічної влади  з питань євроатлантичної інтеграції та їх залученість до інтеграційних процесів
                <br />Пріоритетні завдання у сфері інформаційної взаємодії (комунікації) з питань євроатлантичної інтеграції України для посадових осіб державних органів, установ та організацій, безпосередньо залучених до виконання заходів євроатлантичної інтеграції
                <br />Перспективи та стратегічні напрями євроатлантичної інтеграції, включення їх у комунікаційні плани відповідних державних органів, установ та організацій, інформування цільових аудиторій про хід реалізації таких проектів
                <br /><b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /><b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Шляхи та механізми реалізації комунікаційних заходів з питань євроатлантичної інтеграції України у сфері публічного управління та адміністрування.
            </p>
            <br />
            <p>
                <b>Тема 4. Перспективи розвитку системної інформаційної взаємодії (комунікації) з питань євроатлантичної інтеграції
                    <br />Перелік питань для вивчення: </b>
                <br />Важливість ролі системної інформаційної взаємодії (комунікації) у підвищенні ефективності діяльності з розроблення та реалізації державних політик у різних сферах
                <br />Забезпечення надання зручного та інклюзивного доступу до об'єктивної, повної та своєчасної інформації про діяльність органів державної влади з питань євроатлантичної інтеграції
                <br />Особливості формування позиції громадян України щодо євроатлантичної інтеграції та ефективність дій держави із зазначених питань
                <br />Гармонізація процесів формування та реалізації галузевих державних політик та річних національних програм в контексті євроатлантичної інтеграції
                <br />Активна інформаційна взаємодія (комунікація) з питань євроатлантичної інтеграції щодо перебігу та досягнутих результатів реформ, заходів, комунікаційних програм з питань євроатлантичної інтеграції – забезпечення регулярного моніторингу стану реалізації реформ, заходів, комунікаційних програм з питань євроатлантичної інтеграції

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Системна інформаційна взаємодія (комунікації) з питань євроатлантичної інтеграції на основі використання Концепції <b> Державної цільової соціальної програми національно-патріотичного виховання на період до 2025 року (Розпорядження Кабінету Міністрів України </b> <a href="https://zakon.rada.gov.ua/laws/show/1233-2020-%D1%80#Text" target="_blank">від 09.10.2020 р. № 1233-р </a> «Про схвалення Концепції Державної цільової соціальної програми національно-патріотичного виховання на період до 2025 року».
            </p>

            <h5 className="text-center mt-5">ОЦІНЮВАННЯ РЕЗУЛЬТАТІВ НАВЧАННЯ </h5>
            <p>
                Оцінювання результатів навчання здійснюється за результатами вхідного, поточного та підсумкового контролю:
                <br />	Знання слухачів оцінюється за 100 бальною шкалою на основі:
                <br />
                <ul>
                    <li>відвідування занять – 40%</li>
                    <li>опрацювання обов’язкової літератури, інших матеріалів у вигляді опитування – 10 % </li>
                    <li>підсумкового контролю у вигляді комп’ютерного тестування ‑ 50%.</li>
                </ul>
            </p>
            <p>
                Документ про підвищення кваліфікації видається за умови набрання учасником професійного навчання не менше ніж 70%, обрахованих з урахуванням питомої ваги кожного із критеріїв оцінювання.
            </p>

            <h5 className="text-center mt-5">ЛІТЕРАТУРА, ІНФОРМАЦІЙНІ РЕСУРСИ, ОБОВ’ЯЗКОВІ ДЛЯ ОПРАЦЮВАННЯ</h5>
            <ol>
                <li>Північноатлантичний договір : НАТО; Договір, Пакт, Міжнародний документ <a href="https://zakon.rada.gov.ua/laws/show/950_008#Text" target="_blank"> від 04.04.1949</a> .</li>
                <li>Положення про річні національні програми під егідою Комісії Україна – НАТО : Указ Президента України <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/72/2021#Text"> від 24.02.2021 № 72/2021</a> .</li>
                <li> Про затвердження плану заходів щодо реалізації Стратегії комунікації з питань євроатлантичної інтеграції України на період до 2025 року : Розпорядження Кабінету Міністрів України <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/41-2022-%D1%80#Text"> від 12 січня 2022 р. № 41-р</a> .</li>
                <li> Про національну безпеку України : Закон України <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/2469-19#Text"> від 21.06.2018 № 2469-VIII</a> .</li>
                <li> Про Стратегію комунікації з питань євроатлантичної інтеграції України на період до 2025 року : Указ Президента України <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/348/2021#Text"> від 11.08.2021 № 348/2021</a> .</li>
                <li> Про Стратегію національної безпеки України : Рішення РНБО <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/n0005525-20#Text"> від 14.09.2020</a> .</li>
                <li> Хартія про особливе партнерство між Україною та Організацією Північно-Атлантичного договору : Україна, НАТО; Хартія, Міжнародний документ <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/994_002#Text"> від 09.07.1997</a> .</li>
                <li> Бень Я. О. (2020). Євроатлантична інтеграція України: темпоральна дискретність. Інвестиції: практика та досвід.  <a target="_blank" href="http://www.investplan.com.ua/?op=1&z=7086&i=24"> № 5-6. С. 158–162 </a> .</li>
                <li> Бень Я. О. (2021). Парламентський вимір євроатлантичної інтеграції України. Інвестиції: практика та досвід <a target="_blank" href="http://www.investplan.com.ua/?op=1&z=7476&i=22"> № 10. С. 153–158 </a> .</li>
                <li> Смілянець, В. (2021). Євроатлантична інтеграція України: становлення, розвиток і перспективи. Старожитності Лукомор’я <a target="_blank" href="https://doi.org/10.33782/2708-4116.2021.5.120"> (5), 147-155</a> .</li>
                <li> Україна-НАТО. <a target="_blank" href="https://ukraine-nato.mfa.gov.ua/ukrayina-nato"> Офіційний веб-сайт Міністерства закордонних справ України. </a> .</li>
                <li> Урядовий офіс координації європейської та євроатлантичної інтеграції. <a target="_blank" href="https://eu-ua.kmu.gov.ua"> Офіційний веб-портал </a> .</li>
                <li> Фільм  <a target="_blank" href="https://www.youtube.com/watch?v=cufR0KpJZ_Q&list=PLS1g-GgeUlij8qeJo6xA6lbQ5XzpD-x13&index=7"> «НАТО: свій чи чужий?» </a> Медіа Центр «Розмай». 2007.</li>



            </ol>
        </div>,
    },
    {
        id: 'ЗК.2024.002',
        displayId: 'ЗК.2023.002',
        haveTest: false,
        durationHours: 6,
        durationDays: 2,
        credits: 0.2,
        price: 350,
        priceMembers: 0,
        themes: ['Тема 1. Концептуальні основи національної системи стійкості', 'Тема 2. Сфери забезпечення національної системи стійкості', 'Тема 3. Механізми забезпечення національної системи стійкості'],
        docs: [{ name: 'Презентація', value: 'https://firebasestorage.googleapis.com/v0/b/web-unapam.appspot.com/o/programs%2F2%2F%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D1%96%D1%8F_002.pptx?alt=media&token=2f3a85ce-dd27-4e69-824e-8528e45f53a5&_gl=1*w4x2rm*_ga*NDA1NjQwNTQyLjE2OTMyMTM5MDQ.*_ga_CW55HF8NVT*MTY5OTQzNzUwNi44NC4xLjE2OTk0Mzc5ODEuNjAuMC4w' }],
        files: [],
        name: '«Забезпечення національної стійкості в умовах запобігання, реагування та подолання наслідків дії загроз і кризових ситуацій в Україні»',
        value: <div className="kyrs-info my-5">
            <h5 className="text-center mt-5">ПРОФІЛЬ ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={2}>1. Загальна інформація</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Назва програми  </td>
                        <td>Забезпечення національної стійкості в умовах запобігання, реагування та подолання наслідків дії загроз і кризових ситуацій в Україні </td>
                    </tr>
                    <tr>
                        <td>Шифр програми</td>
                        <td>ЗК/2024/002 </td>
                    </tr>
                    <tr>
                        <td>Тип програми за змістом</td>
                        <td>загальна короткострокова програма підвищення кваліфікації</td>
                    </tr>
                    <tr>
                        <td>Форма навчання</td>
                        <td>дистанційна в асинхронному режимі</td>
                    </tr>
                    <tr>
                        <td>Цільова група</td>
                        <td>державні службовці, які займають посади державної служби категорій «Б» та «В»; посадові особи місцевого самоврядування та фахівці, в професійній діяльності яких виникає потреба запобігання, реагування та подолання наслідків дії загроз і кризових ситуацій з метою забезпечення національної стійкості
                        </td>
                    </tr>
                    <tr>
                        <td>Передумови навчання за програмою</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування замовника освітніх послуг у сфері професійного навчання за програмою-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування партнера (партнерів) програми</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Обсяг програми</td>
                        <td>0,2 кредит ЄКТС</td>
                    </tr>
                    <tr>
                        <td>Тривалість програми та організація навчання</td>
                        <td>загальна тривалість програми: 1 день</td>
                    </tr>
                    <tr>
                        <td>Мова(и) викладання</td>
                        <td>українська</td>
                    </tr>
                    <tr>
                        <td>Напрям(и) підвищення кваліфікації, який (які) охоплює програма</td>
                        <td>забезпечення національної стійкості</td>
                    </tr>
                    <tr>
                        <td>Перелік професійних компетентностей, на підвищення рівня яких спрямовано програму </td>
                        <td>знання концептуальних засад національної системи стійкості
                            в Україні
                            вміння вирішувати комплексні завдання та вдало
                            використовувати ресурси, надавати пропозиції в умовах
                            запобігання, реагування та подолання наслідків дії загроз і
                            кризових ситуацій в Україні
                            здатність приймати зміни та змінюватись відповідно до
                            державної політики національної стійкості та дії загроз і
                            кризових ситуацій в Україні  </td>
                    </tr>
                    <tr>
                        <td>Укладач(і) програми</td>
                        <td>Карпа Марта Іванівна, доктор наук з державного управління,
                            професор, професор кафедри публічного управління та
                            адміністрування Університету Григорія Сковороди в
                            Переяславі, голова ГО «Національна асоціація публічного управління та адміністрування України»</td>
                    </tr>

                    <tr>
                        <th className="text-center" colSpan={2}>2. Загальна мета</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Підвищення професійних компетентностей державних службовців, посадових осіб
                            місцевого самоврядування, фахівців, в професійній діяльності яких виникає потреба
                            запобігання, реагування та подолання наслідків дії загроз і кризових ситуацій з метою
                            забезпечення національної стійкості</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>3. Очікувані результати навчання</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>За результатами навчання слухачі повинні демонструвати:</td>
                    </tr>
                    <tr>
                        <td>знання</td>
                        <td>засад про національну стійкість
                            концептуальних основ забезпечення національної стійкості
                            в умовах запобігання, реагування та подолання наслідків дії
                            загроз і кризових ситуацій в Україні</td>
                    </tr>
                    <tr>
                        <td>уміння</td>
                        <td>реагувати на загрози національній безпеці, надзвичайні та
                            кризові ситуації різного походження на будь-яких етапах
                            ідентифікувати загрози, виявляти вразливості та оцінювати
                            ризики безпеці, запобігати або мінімізувати їх негативні
                            впливи, ефективно реагувати та швидко і повномасштабно
                            відновлюватися після виникнення загроз або настання
                            надзвичайних та кризових ситуацій усіх видів, включаючи
                            загрози гібридного типу</td>
                    </tr>
                    <tr>
                        <td>навички</td>
                        <td>здатність ефективно протидіяти загрозам, надзвичайним і
                            кризовим ситуаціям, забезпечити відновлення та стале
                            функціонування основних сфер життєдіяльності держави і
                            суспільства;
                            ідентифікувати, готуватися, реагувати на загрози,
                            адаптуватися до змін безпекового середовища,
                            підтримувати стале функціонування до, під час і після
                            настання кризової ситуації задля збереження
                            функціонування і подальшого розвитку</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>4. Викладання та навчання (методи навчання, форми проведення навчальних занять)</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Дистанційне навчання через доступ до навчальних матеріалів через вебсередовище в асинхронному режимі (режимі відкладеного часу) із застосуванням електронної пошти; самостійне опрацювання рекомендованої літератури, інформаційних ресурсів. Методи навчання – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований. </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>5. Ресурсне забезпечення дистанційного навчання</th>
                    </tr>
                    <tr>
                        <td>Назви вебплатформи, вебсайту, електронної системи навчання, через які здійснюватиметься дистанційне навчання із зазначенням посилання (вебадреси)</td>
                        <td>Вебадреса: www.unapam.com <br />
                            електронна пошта: officeUNAPAM@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Назва дистанційного етапу/модуля</td>
                        <td>Тема 1 . Концептуальні основи національної системи стійкості
                            <br />  Тема 2 . Сфери забезпечення національної системи стійкості
                            <br /> Тема 3. Механізми забезпечення національної системи стійкості
                        </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>6. Оцінювання і форми поточного, підсумкового контролю</th>
                    </tr>
                    <tr>
                        <td>Складові оцінювання та їх питома вага у підсумковій оцінці (%)</td>
                        <td>Відвідування занять у дистанційному форматі – 90%
                            Опрацювання обов’язкової літератури, інформаційних та
                            інших матеріалів – 10%
                            Підсумковий контроль – 0%*
                            Документ про підвищення кваліфікації видається за умови
                            набрання учасником професійного навчання не менше ніж 75
                            %, обрахованих з урахуванням питомої ваги кожного із
                            критеріїв оцінювання.</td>
                    </tr>
                    <tr>
                        <td>Форма підсумкового контролю</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>

            <p className="f-italic px-5">
                *Оцінювання результатів навчання за короткостроковою програмою обсягом до
                0,5 кредиту ЄКТС відповідно до  пункту 9  розділу IV Порядку не здійснюється
                відповідно до Методичних рекомендацій щодо розроблення та оформлення
                програм підвищення кваліфікації державних службовців, голів місцевих державних
                адміністрацій, їх перших заступників та заступників, посадових осіб місцевого
                самоврядування та депутатів місцевих рад (Наказ НАДС № 38-20 від
                13.03.2020р.).
            </p>


            <h5 className="text-center mt-5">СТРУКТУРА ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle table-responsive">
                <thead>
                    <tr>
                        <th className="text-center" rowSpan={3} >Назва теми </th>
                        <th colSpan={6}>Кількість годин </th>
                    </tr>
                    <tr>
                        <th className="text-center" rowSpan={2} >загальна кількість годин/ кредитів ЄКТС</th>
                        <th className="text-center" colSpan={6}>у тому числі: </th>

                    </tr>
                    <tr>
                        <th className="text-center" >аудиторні заняття</th>
                        <th className="text-center" >дистанційні заняття </th>
                        <th className="text-center" >навчальні візити </th>
                        <th className="text-center" >самостійна робота слухачів </th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                    </tr>
                    <tr>
                        <td>Тема 1  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>1</td>
                        <td></td>
                        <td>0,33</td>
                    </tr>
                    <tr>
                        <td>Тема 2  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>0,33</td>
                    </tr>
                    <tr>
                        <td>Тема 3  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>0,34</td>
                    </tr>
                    <tr>
                        <th> РАЗОМ </th>
                        <th>6/0,2</th>
                        <th></th>
                        <th>5/0,166</th>
                        <th></th>
                        <th>1/0,034</th>
                    </tr>
                </tbody>
            </table>


            <h5 className="text-center mt-5">ЗМІСТ ПРОГРАМИ</h5>
            <p><b>Тема 1. Концептуальні основи національної системи стійкості.</b>
                <br /> <b>Перелік питань для вивчення: </b>
                <br /> Нормативно-правова основа забезпечення національної системи стійкості
                Організаційні засади впровадження та функціонування національної системи
                стійкості в умовах запобігання, реагування та подолання наслідків дії загроз і кризових
                ситуацій в Україні

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Аналіз Концепції забезпечення національної системи стійкості (Указ
                Президента України; Концепція від 27.09.2021 № 479/2021).
            </p>
            <br />
            <p>
                <b>Тема 2. Сфери забезпечення національної системи стійкості.
                    <br /> Перелік питань для вивчення: </b>
                <br /> Питання забезпечення готовності та реагування на загрози, надзвичайні та кризові
                ситуації. Базові елементи впровадження національної системи стійкості
                Безпека та захищеність об’єктів критичної інфраструктури


                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Про схвалення Концепції створення державної системи захисту критичної
                інфраструктури : Розпорядження Кабінету Міністрів України;
                Концепція <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/1009-2017-%D1%80#Text">від 06.12.2017 № 1009-р</a>  .
            </p>
            <br />
            <p>
                <b>Тема 3. Механізми забезпечення національної системи стійкості.
                    <br />Перелік питань для вивчення: </b>
                <br />Механізми інституційного забезпечення національної системи стійкості
                Актуальні питання оцінювання ризиків національній безпеці й стану відповідних
                спроможностей
                <br />Міжвідомча взаємодія з питань забезпечення національної безпеки і стійкості
                <br /><b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /><b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Ознайомлення з основними проблемами, які потребують розв’язання на етапі
                запровадження національної системи стійкості відповідно до Концепції забезпечення
                національної системи стійкості та практики впровадження національної системи стійкості.
            </p>


            <h5 className="text-center mt-5">ОЦІНЮВАННЯ РЕЗУЛЬТАТІВ НАВЧАННЯ </h5>
            <p>

                <br />	Відвідування занять у дистанційному форматі – 90%

            </p>
            <p>
                Опрацювання обов’язкової літератури, інформаційних та інших матеріалів – 10%
            </p>
            <p>Підсумковий контроль – 0%*</p>
            <p>
                Документ про підвищення кваліфікації видається за умови відвідування занять,
                здійснення самостійної роботи, опрацювання обов’язкової літератури, інших матеріалів.
                Документ про підвищення кваліфікації видається за умови набрання учасником
                професійного навчання не менше ніж 75%, обрахованих з урахуванням питомої ваги кожного
                із критеріїв оцінювання.
            </p>
            <p className="f-italic px-5">
                *Оцінювання результатів навчання за короткостроковою програмою обсягом до 0,5
                кредиту ЄКТС відповідно до  пункту 9  розділу IV Порядку не здійснюється відповідно до
                Методичних рекомендацій щодо розроблення та оформлення програм підвищення
                кваліфікації державних службовців, голів місцевих державних адміністрацій, їх перших
                заступників та заступників, посадових осіб місцевого самоврядування та депутатів місцевих
                рад (Наказ НАДС № 38-20 від 13.03.2020р.).
            </p>

            <h5 className="text-center mt-5">ЛІТЕРАТУРА, ІНФОРМАЦІЙНІ РЕСУРСИ, ОБОВ’ЯЗКОВІ ДЛЯ ОПРАЦЮВАННЯ</h5>
            <ol>
                <li> Деякі питання об'єктів критичної інфраструктури : Постанова Кабінету Міністрів України; Порядок, Перелік, Методика <a href="https://zakon.rada.gov.ua/laws/show/1109-2020-%D0%BF#Text" target="_blank"> від 09.10.2020 № 1109 </a> .</li>
                <li> Національна платформа стійкості та згуртованості : <a href="https://national-platform.org/" target="_blank"> Офіційний веб-портал </a> .</li>
                <li> Національні системи оцінювання ризиків і загроз: кращі світові практики, нові можливості для України : аналіт. доп. / [Резнікова О. О., Войтовський К. Є. Лепіхов А. В.]; за заг. ред. О. О. Резнікової. Київ : НІСД, 2020. 84 с.
                    .</li>
                <li> Пирожков, С. І. (2022). Про Національну доповідь НАН України «Національна стійкість України: стратегія відповіді на виклики та випередження гібридних загроз»: За матеріалами доповіді на засіданні Президії НАН України 6 квітня 2022 року <a href=" https://doi.org/10.15407/visn2022.05.045" target="_blank"> Вісник НАН України, (5), 45–55 </a> .</li>
                <li> Про затвердження Порядку проведення оборонного огляду Міністерством оборони : Постанова Кабінету Міністрів України; Порядо<a href="https://zakon.rada.gov.ua/laws/show/941-2018-%D0%BF#n10" target="_blank"> від 31.10.2018 № 941 </a> .</li>

                <li> Про затвердження Порядку проведення огляду громадської безпеки та цивільного захисту Міністерством внутрішніх справ : Постанова Кабінету Міністрів України <a href="https://zakon.rada.gov.ua/laws/show/507-2019-%D0%BF#Text" target="_blank">  №507 від 22.05.2019р  </a> .</li>
                <li> Про національну безпеку України : Закон України <a href="https://zakon.rada.gov.ua/laws/show/2469-19#Text" target="_blank"> від 21.06.2018 №2469-VII </a> .</li>
                <li> Про рішення Ради національної безпеки і оборони України від 14 вересня 2020 року «Про Стратегію національної безпеки України» : Указ Президента України <a href="https://www.president.gov.ua/documents/3922020-35037" target="_blank"> № 392/2020 від 14.09.2020р </a> .</li>
                <li> Про рішення Ради національної безпеки і оборони України від 20 серпня 2021 року "Про запровадження національної системи стійкості" : Указ Президента України; Концепці <a href="https://zakon.rada.gov.ua/laws/show/479/2021#Text" target="_blank">  від 27.09.2021 № 479/2021 </a> .</li>
                <li> Про схвалення Концепції створення державної системи захисту критичної інфраструктури : Розпорядження Кабінету Міністрів України; Концепція <a href="https://zakon.rada.gov.ua/laws/show/1009-2017-%D1%80#Text" target="_blank">  від 06.12.2017 № 1009-р </a> .</li>
                <li> Рафальський О.О. Загрози Україні в нових геополітичних умовах: ризики та шляхи подолання.  <a href="https://files.nas.gov.ua/PublicMessages/Documents/0/2023/08/230823145607607-6023.pdf" target="_blank"> Вісн. НАН України, 2023, № 8. </a> .</li>
                <li> Резнікова О.О. (2019). Розбудова національної стійкості: концептуальні підходи, передові світові практики. <a href="https://niss.gov.ua/sites/default/files/2019-11/roa_presentation_niss_v01.pdf" target="_blank"> Презентаційні матеріали </a> .</li>



            </ol>
        </div>,
    },
    {
        id: 'ЗК.2024.003',
        displayId: 'ЗК.2023.003',
        haveTest: false,
        durationHours: 6,
        durationDays: 2,
        credits: 0.2,
        price: 350,
        priceMembers: 350,
        themes: [
            'Тема 1. Міжнародне гуманітарне право та його роль в умовах збройного конфлікту в Україні',
            'Тема 2. Особливості застосування міжнародного гуманітарного права в умовах збройного конфлікту в Україні',
            'Тема 3. Порушення міжнародного гуманітарного права та способи протидії їм'
        ],
        docs: [{ name: 'Презентація', value: 'https://firebasestorage.googleapis.com/v0/b/web-unapam.appspot.com/o/programs%2F3%2F%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D1%96%D1%8F_003.pptx?alt=media&token=751b0fd5-d357-44f3-b9ab-483ed3633a4c&_gl=1*1waox1p*_ga*NDA1NjQwNTQyLjE2OTMyMTM5MDQ.*_ga_CW55HF8NVT*MTY5OTQ0MDcwMi44NS4xLjE2OTk0NDA3MzIuMzAuMC4w' }],
        files: [],
        name: '«Застосування міжнародного гуманітарного права в умовах збройного конфлікту в Україні»',
        value: <div className="kyrs-info my-5">
            <h5 className="text-center mt-5">ПРОФІЛЬ ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={2}>1. Загальна інформація</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Назва програми  </td>
                        <td>Застосування міжнародного гуманітарного права в умовах збройного конфлікту в Україні</td>
                    </tr>
                    <tr>
                        <td>Шифр програми</td>
                        <td>ЗК/2024/003 </td>
                    </tr>
                    <tr>
                        <td>Тип програми за змістом</td>
                        <td>загальна короткострокова програма підвищення кваліфікації</td>
                    </tr>
                    <tr>
                        <td>Форма навчання</td>
                        <td>дистанційна в асинхронному режимі</td>
                    </tr>
                    <tr>
                        <td>Цільова група</td>
                        <td>державні службовці, які займають посади державної служби категорій «Б» та «В»; посадові особи місцевого самоврядування та фахівці, в професійній діяльності яких виникає потреба застосування міжнародного гуманітарного права в умовах збройного конфлікту в Україні та усіх зацікавлених у вивченні міжнародного гуманітарного права</td>
                    </tr>
                    <tr>
                        <td>Передумови навчання за програмою</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування замовника освітніх послуг у сфері професійного навчання за програмою-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування партнера (партнерів) програми</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Обсяг програми</td>
                        <td>0,2 кредит ЄКТС</td>
                    </tr>
                    <tr>
                        <td>Тривалість програми та організація навчання</td>
                        <td>загальна тривалість програми: 1 день</td>
                    </tr>
                    <tr>
                        <td>Мова(и) викладання</td>
                        <td>українська</td>
                    </tr>
                    <tr>
                        <td>Напрям(и) підвищення кваліфікації, який (які) охоплює програма</td>
                        <td>застосування міжнародного гуманітарного права в умовах збройного конфлікту</td>
                    </tr>
                    <tr>
                        <td>Перелік професійних компетентностей, на підвищення рівня яких спрямовано програму </td>
                        <td>знання засад застосування міжнародного гуманітарного права в умовах збройного конфлікту в Україні
                            <br />
                            вміння вирішувати комплексні завдання та вдало використовувати ресурси міжнародного гуманітарного права в умовах збройного конфлікту в Україні
                            <br />
                            виконання норм міжнародного гуманітарного права</td>
                    </tr>
                    <tr>
                        <td>Укладач(і) програми</td>
                        <td>Карпа Марта Іванівна, доктор наук з державного управління,
                            професор, професор кафедри публічного управління та
                            адміністрування Університету Григорія Сковороди в
                            Переяславі, голова ГО «Національна асоціація публічного управління та адміністрування України»</td>
                    </tr>

                    <tr>
                        <th className="text-center" colSpan={2}>2. Загальна мета</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Підвищення професійних компетентностей державних службовців, посадових осіб
                            місцевого самоврядування, фахівців, інших осіб в професійній діяльності яких виникає
                            потреба застосування міжнародного гуманітарного права в умовах збройного конфлікту в
                            Україні</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>3. Очікувані результати навчання</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>За результатами навчання слухачі повинні демонструвати:</td>
                    </tr>
                    <tr>
                        <td>знання</td>
                        <td>концептуальних основ міжнародного гуманітарного права в
                            умовах збройного конфлікту в Україні та можливостей його
                            застосування джерел міжнародного гуманітарного права</td>
                    </tr>
                    <tr>
                        <td>уміння</td>
                        <td>дотримання норм міжнародного гуманітарного права в
                            умовах збройного конфлікту в Україні;
                            забезпечення та сприяння реалізації державної політики з
                            питань дотримання норм міжнародного гуманітарного
                            права на всій території України
                            користуваня джерелами міжнародного гуманітарного права</td>
                    </tr>
                    <tr>
                        <td>навички</td>
                        <td>застосування норм і принципів міжнародного гуманітарного
                            права щодо правопорушень, вчинених в умовах збройного
                            конфлікту;
                            дотримання норм міжнародного гуманітарного права на
                            всій території України</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>4. Викладання та навчання (методи навчання, форми проведення навчальних занять)</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Дистанційне навчання через доступ до навчальних матеріалів через вебсередовище в асинхронному режимі (режимі відкладеного часу) із застосуванням електронної пошти; самостійне опрацювання рекомендованої літератури, інформаційних ресурсів. Методи навчання – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований. </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>5. Ресурсне забезпечення дистанційного навчання</th>
                    </tr>
                    <tr>
                        <td>Назви вебплатформи, вебсайту, електронної системи навчання, через які здійснюватиметься дистанційне навчання із зазначенням посилання (вебадреси)</td>
                        <td>Вебадреса: www.unapam.com <br />
                            електронна пошта: officeUNAPAM@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Назва дистанційного етапу/модуля</td>
                        <td>Тема 1 . Міжнародне гуманітарне право та його роль в умовах збройного конфлікту в Україні
                            <br />  Тема 2 . Особливості застосування міжнародного гуманітарного права в умовах збройного конфлікту в Україні
                            <br /> Тема 3. Порушення міжнародного гуманітарного права та способи протидії їм.
                        </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>6. Оцінювання і форми поточного, підсумкового контролю</th>
                    </tr>
                    <tr>
                        <td>Складові оцінювання та їх питома вага у підсумковій оцінці (%)</td>
                        <td>Відвідування занять у дистанційному форматі – 90%
                            Опрацювання обов’язкової літератури, інформаційних та
                            інших матеріалів – 10%
                            Підсумковий контроль – 0%*
                            Документ про підвищення кваліфікації видається за умови
                            набрання учасником професійного навчання не менше ніж 75
                            %, обрахованих з урахуванням питомої ваги кожного із
                            критеріїв оцінювання.</td>
                    </tr>
                    <tr>
                        <td>Форма підсумкового контролю</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>

            <p className="f-italic px-5">
                *Оцінювання результатів навчання за короткостроковою програмою обсягом до
                0,5 кредиту ЄКТС відповідно до  пункту 9  розділу IV Порядку не здійснюється
                відповідно до Методичних рекомендацій щодо розроблення та оформлення
                програм підвищення кваліфікації державних службовців, голів місцевих державних
                адміністрацій, їх перших заступників та заступників, посадових осіб місцевого
                самоврядування та депутатів місцевих рад (Наказ НАДС № 38-20 від
                13.03.2020р.).
            </p>


            <h5 className="text-center mt-5">СТРУКТУРА ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle table-responsive">
                <thead>
                    <tr>
                        <th className="text-center" rowSpan={3} >Назва теми </th>
                        <th colSpan={6}>Кількість годин </th>
                    </tr>
                    <tr>
                        <th className="text-center" rowSpan={2} >загальна кількість годин/ кредитів ЄКТС</th>
                        <th className="text-center" colSpan={6}>у тому числі: </th>

                    </tr>
                    <tr>
                        <th className="text-center" >аудиторні заняття</th>
                        <th className="text-center" >дистанційні заняття </th>
                        <th className="text-center" >навчальні візити </th>
                        <th className="text-center" >самостійна робота слухачів </th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                    </tr>
                    <tr>
                        <td>Тема 1  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>1</td>
                        <td></td>
                        <td>0,33</td>
                    </tr>
                    <tr>
                        <td>Тема 2  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>0,33</td>
                    </tr>
                    <tr>
                        <td>Тема 3  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>0,34</td>
                    </tr>
                    <tr>
                        <th> РАЗОМ </th>
                        <th>6/0,2</th>
                        <th></th>
                        <th>5/0,166</th>
                        <th></th>
                        <th>1/0,034</th>
                    </tr>
                </tbody>
            </table>


            <h5 className="text-center mt-5">ЗМІСТ ПРОГРАМИ</h5>
            <p><b>Тема 1. Міжнародне гуманітарне право та його роль в умовах збройного конфлікту в Україні</b>
                <br /> <b>Перелік питань для вивчення: </b>
                <br /> Основні норми, завдання міжнародного гуманітарного права
                <br /> Джерела міжнародного гуманітарного права

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Аналіз основних положень Конвенції про захист цивільного населення під час
                війни та Додаткових протоколів до неї. (Джерело - Конвенція про захист цивільного
                населення під час війни : Конвенція ООН <a href="https://zakon.rada.gov.ua/laws/show/995_154#Text" target="_blank">від 12.08.1949</a> ).
            </p>
            <br />
            <p>
                <b>Тема 2. Особливості застосування міжнародного гуманітарного права в умовах збройного конфлікту в Україні
                    <br /> Перелік питань для вивчення: </b>
                <br /> Міжвідомча Комісія з питань застосування та реалізації норм міжнародного гуманітарного права в Україні
                <br /> Особливості застосування та виконання норм міжнародного гуманітарного права в умовах збройного конфлікту в Україні

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Аналіз основних положень Інструкції про порядок виконання норм міжнародного
                гуманітарного права у Збройних Силах України . (Джерело - Про затвердження
                Інструкції про порядок виконання норм міжнародного гуманітарного права у Збройних Силах України : Наказ Міноборони України
                <a href="https://zakon.rada.gov.ua/laws/show/z0704-17#Text" target="_blank">від 23.03.2017 № 164</a>)
            </p>
            <br />
            <p>
                <b>Тема 3. Порушення міжнародного гуманітарного права та способи протидії їм.
                    <br />Перелік питань для вивчення: </b>
                <br />Воєнні злочини та інші порушення міжнародного гуманітарного права в період збройного конфлікту в Україні
                <br />Нормативно-правове забезпечення реалізації міжнародного гуманітарного права в умовах збройного конфлікту в Україні
                <br /><b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /><b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Юридична відповідальність у міжнародному гуманітарному праві: особливості та види.


            </p>


            <h5 className="text-center mt-5">ОЦІНЮВАННЯ РЕЗУЛЬТАТІВ НАВЧАННЯ </h5>
            <p>

                <br />	Відвідування занять у дистанційному форматі – 90%

            </p>
            <p>
                Опрацювання обов’язкової літератури, інформаційних та інших матеріалів – 10%
            </p>
            <p>Підсумковий контроль – 0%*</p>
            <p>
                Документ про підвищення кваліфікації видається за умови відвідування занять,
                здійснення самостійної роботи, опрацювання обов’язкової літератури, інших матеріалів.
                Документ про підвищення кваліфікації видається за умови набрання учасником
                професійного навчання не менше ніж 75%, обрахованих з урахуванням питомої ваги кожного
                із критеріїв оцінювання.
            </p>
            <p className="f-italic px-5">
                *Оцінювання результатів навчання за короткостроковою програмою обсягом до 0,5
                кредиту ЄКТС відповідно до  пункту 9  розділу IV Порядку не здійснюється відповідно до
                Методичних рекомендацій щодо розроблення та оформлення програм підвищення
                кваліфікації державних службовців, голів місцевих державних адміністрацій, їх перших
                заступників та заступників, посадових осіб місцевого самоврядування та депутатів місцевих
                рад (Наказ НАДС № 38-20 від 13.03.2020р.).
            </p>

            <h5 className="text-center mt-5">ЛІТЕРАТУРА, ІНФОРМАЦІЙНІ РЕСУРСИ, ОБОВ’ЯЗКОВІ ДЛЯ ОПРАЦЮВАННЯ</h5>
            <ol>
                <li> Про затвердження Інструкції про порядок виконання норм міжнародного гуманітарного права у Збройних Силах України : Наказ Міноборони України
                    <a href="https://zakon.rada.gov.ua/laws/show/z0704-17#Text" target="_blank"> від 23.03.2017 №164 </a>.
                </li>
                <li> Панфілов О. Ю., Лашин Д. Є. Особливості ведення гібридних війн у новітній
                    історії світу. Український соціум: соціально-політичний аналіз сучасності та прогноз
                    майбутнього : Збірник матеріалів XХІІ Всеукраїнської наукової конференції викладачів,
                    молодих науковців і студентів (м. Харків, 1 грудня 2020 р.). Харків : Право, 2020.
                    <a href="http://surl.li/ejbqu" target="_blank"> С. 17-19. </a>.
                </li>
                <li> Конвенція про захист цивільного населення під час війни : Конвенція ООН
                    <a href="https://zakon.rada.gov.ua/laws/show/995_154#Text" target="_blank"> від 12.08.1949 </a>.
                </li>
                <li> Гаазька конвенція про закони і звичаї війни на суходолі та додатки до неї, Гаага: Міжнародний документ
                    <a href="https://zakon.rada.gov.ua/laws/show/995_222#n2u" target="_blank"> від 18.10.1907р. </a>.
                </li>
                <li> Римський Статут Міжнародного Кримінального Суду . Міжнародні суди, ООН : Статут, Міжнародний документ, Поправки
                    <a href="https://zakon.rada.gov.ua/laws/show/995_588#Text" target="_blank"> від 17.07.1998 </a>.
                </li>
                <li> Конвенція про незастосування строку давності до воєнних злочинів і злочинів проти людства
                    <a href="https://zakon.rada.gov.ua/laws/show/995_168#Text" target="_blank"> 1968 року </a>.
                </li>
                <li> Міжнародне гуманітарне право : навчально-методичний посібник / за ред.
                    завідувача кафедри міжнародного та європейського права, професора О. В. Бігняка. – Одеса :
                    Видавничий дім «Гельветика», 2022. – 136 с.
                </li>
                <li>Про Заяву Верховної Ради України &quot;Про вчинення Російською Федерацією геноциду в Україні&quot; : Постанова Верховної Ради України
                    <a href="https://zakon.rada.gov.ua/laws/show/2188-20#Text" target="_blank"> від 14.04.2022 №2188-IX </a>.
                </li>
                <li> Кримінальний кодекс України : Кодекс України
                    <a href="https://zakon.rada.gov.ua/laws/show/2341-14#Text" target="_blank"> від 05.04.2001 № 2341-III </a>.
                </li>
                <li> Міжнародне гуманітарне право.
                    <a href="https://osvita.diia.gov.ua/courses/iternational-humanitarian-law" target="_blank"> Освітній серіал. Освіта. Дія. </a>.
                </li>
            </ol>
        </div>,
    },
    {
        id: 'ЗК.2024.004',
        displayId: 'ЗК.2023.004',
        haveTest: false,
        durationHours: 6,
        durationDays: 2,
        credits: 0.2,
        price: 350,
        priceMembers: 350,
        themes: [
            'Тема 1. Організаційно-правові засади забезпечення виконання рішень Європейського суду з прав людини в Україні',
            'Тема 2. Конвенційна система нормативно-правового забезпечення виконання рішень Європейського суду з прав людини',
            'Тема 3. Практика виконання рішень Європейського суду з прав людини в Україні: актуальні проблеми та перспективи їх вирішення'
        ],
        docs: [{ name: 'Презентація', value: '' }],
        files: [],
        name: '«Забезпечення виконання рішень Європейського суду з прав людини в Україні»',
        value: <div className="kyrs-info my-5">
            <h5 className="text-center mt-5">ПРОФІЛЬ ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={2}>1. Загальна інформація</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Назва програми  </td>
                        <td>Забезпечення виконання рішень Європейського суду з прав людини в Україні</td>
                    </tr>
                    <tr>
                        <td>Шифр програми</td>
                        <td>ЗК/2024/004 </td>
                    </tr>
                    <tr>
                        <td>Тип програми за змістом</td>
                        <td>загальна короткострокова програма підвищення кваліфікації</td>
                    </tr>
                    <tr>
                        <td>Форма навчання</td>
                        <td>дистанційна </td>
                    </tr>
                    <tr>
                        <td>Цільова група</td>
                        <td>державні службовці, які займають посади державної служби

                            категорій «Б» та «В»; посадові особи місцевого
                            самоврядування та фахівці, в професійній діяльності яких
                            виникає потреба вивчення та впровадження європейських
                            норм щодо захисту прав людини та основоположних свобод,
                            вивчення діяльності Європейського суду з прав людини в
                            України, зокрема в частині виконання рішень Європейського
                            суду з прав людини в Україні</td>
                    </tr>
                    <tr>
                        <td>Передумови навчання за програмою</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування замовника освітніх послуг у сфері професійного навчання за програмою-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування партнера (партнерів) програми</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Обсяг програми</td>
                        <td>0,2 кредит ЄКТС</td>
                    </tr>
                    <tr>
                        <td>Тривалість програми та організація навчання</td>
                        <td>загальна тривалість програми: 1 день</td>
                    </tr>
                    <tr>
                        <td>Мова(и) викладання</td>
                        <td>українська</td>
                    </tr>
                    <tr>
                        <td>Напрям(и) підвищення кваліфікації, який (які) охоплює програма</td>
                        <td>дотримання прав людини та протидія дискримінації; європейська інтеграція</td>
                    </tr>
                    <tr>
                        <td>Перелік професійних компетентностей, на підвищення рівня яких спрямовано програму </td>
                        <td>знання європейських норм щодо захисту прав людини та основоположних свобод
                            <br />
                            вміння користуватися нормативно-правовою основою забезпечення виконання рішень Європейського суду з прав людини в Україні
                            <br />
                            орієнтуватися в практиці виконання рішень Європейського суду з прав людини в Україні</td>
                    </tr>
                    <tr>
                        <td>Укладач(і) програми</td>
                        <td>Карпа Марта Іванівна, доктор наук з державного управління,
                            професор, професор кафедри публічного управління та
                            адміністрування Університету Григорія Сковороди в
                            Переяславі, голова ГО «Національна асоціація публічного управління та адміністрування України»
                            <br />
                            Мукан Іван Васильович, адвокат, керуючий Адвокатського
                            бюро «Івана Мукана», аспірант кафедри управління та
                            бізнес-адміністрування Прикарпатського національного
                            університету імені Василя Стефаника, член ГО «Національна
                            асоціація публічного управління та адміністрування
                            України»
                        </td>
                    </tr>

                    <tr>
                        <th className="text-center" colSpan={2}>2. Загальна мета</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Підвищення професійних компетентностей державних службовців, посадових осіб
                            місцевого самоврядування, фахівців, інших осіб в професійній діяльності яких виникає
                            потреба: застосування європейських норм щодо захисту прав людини та основоположних
                            свобод; імплементації європейської практики розуміння прав людини у вітчизняне
                            законодавство; вивчення та використання практики виконання рішень Європейського суду
                            з прав людини в Україні</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>3. Очікувані результати навчання</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>За результатами навчання слухачі повинні демонструвати:</td>
                    </tr>
                    <tr>
                        <td>знання</td>
                        <td>знання європейських норм щодо захисту прав людини та основоположних свобод, Конвенції про захист прав людини і основоположних свобод
                            <br />
                            знання основних засад виконання рішень Європейського суду з прав людини в Україні
                        </td>
                    </tr>
                    <tr>
                        <td>уміння</td>
                        <td>вміння користуватися нормативно-правовою основою щодо виконання рішень Європейського суду з прав людини в Україні
                            <br />
                            аналізувати міжнародні нормативно-правові акти щодо захисту прав людини
                        </td>
                    </tr>
                    <tr>
                        <td>навички</td>
                        <td>застосування норм і принципів щодо захисту прав людини;
                            дотримання європейських норм щодо захисту прав людини в Україні способів та використання організаційних інструментів
                            забезпечення виконання Конвенції про захист прав людини і основоположних свобод</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>4. Викладання та навчання (методи навчання, форми проведення навчальних занять)</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Дистанційне навчання через доступ до навчальних матеріалів через вебсередовище в асинхронному режимі (режимі відкладеного часу) із застосуванням електронної пошти; самостійне опрацювання рекомендованої літератури, інформаційних ресурсів. Методи навчання – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований. </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>5. Ресурсне забезпечення дистанційного навчання</th>
                    </tr>
                    <tr>
                        <td>Назви вебплатформи, вебсайту, електронної системи навчання, через які здійснюватиметься дистанційне навчання із зазначенням посилання (вебадреси)</td>
                        <td>Вебадреса: www.unapam.com <br />
                            електронна пошта: officeUNAPAM@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Назва дистанційного етапу/модуля</td>
                        <td>Тема 1 . Організаційно-правові засади забезпечення виконання рішень Європейського суду з прав людини в Україні
                            <br />  Тема 2 . Конвенційна система нормативно-правового забезпечення виконання рішень Європейського суду з прав людини
                            <br /> Тема 3. Практика виконання рішень Європейського суду з прав людини в Україні: актуальні проблеми та перспективи їх вирішення
                        </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>6. Оцінювання і форми поточного, підсумкового контролю</th>
                    </tr>
                    <tr>
                        <td>Складові оцінювання та їх питома вага у підсумковій оцінці (%)</td>
                        <td>Відвідування занять у дистанційному форматі – 90%
                            Опрацювання обов’язкової літератури, інформаційних та
                            інших матеріалів – 10%
                            Підсумковий контроль – 0%*
                            Документ про підвищення кваліфікації видається за умови
                            набрання учасником професійного навчання не менше ніж 75
                            %, обрахованих з урахуванням питомої ваги кожного із
                            критеріїв оцінювання.</td>
                    </tr>
                    <tr>
                        <td>Форма підсумкового контролю</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>

            <p className="f-italic px-5">
                *Оцінювання результатів навчання за короткостроковою програмою обсягом до
                0,5 кредиту ЄКТС відповідно до  пункту 9  розділу IV Порядку не здійснюється
                відповідно до Методичних рекомендацій щодо розроблення та оформлення
                програм підвищення кваліфікації державних службовців, голів місцевих державних
                адміністрацій, їх перших заступників та заступників, посадових осіб місцевого
                самоврядування та депутатів місцевих рад (Наказ НАДС № 38-20 від
                13.03.2020р.).
            </p>


            <h5 className="text-center mt-5">СТРУКТУРА ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle table-responsive">
                <thead>
                    <tr>
                        <th className="text-center" rowSpan={3} >Назва теми </th>
                        <th colSpan={6}>Кількість годин </th>
                    </tr>
                    <tr>
                        <th className="text-center" rowSpan={2} >загальна кількість годин/ кредитів ЄКТС</th>
                        <th className="text-center" colSpan={6}>у тому числі: </th>

                    </tr>
                    <tr>
                        <th className="text-center" >аудиторні заняття</th>
                        <th className="text-center" >дистанційні заняття </th>
                        <th className="text-center" >навчальні візити </th>
                        <th className="text-center" >самостійна робота слухачів </th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                    </tr>
                    <tr>
                        <td>Тема 1  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>1</td>
                        <td></td>
                        <td>0,33</td>
                    </tr>
                    <tr>
                        <td>Тема 2  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>0,33</td>
                    </tr>
                    <tr>
                        <td>Тема 3  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>0,34</td>
                    </tr>
                    <tr>
                        <th> РАЗОМ </th>
                        <th>6/0,2</th>
                        <th></th>
                        <th>5/0,166</th>
                        <th></th>
                        <th>1/0,034</th>
                    </tr>
                </tbody>
            </table>


            <h5 className="text-center mt-5">ЗМІСТ ПРОГРАМИ</h5>
            <p><b>Тема 1. Організаційно-правові засади забезпечення виконання рішень Європейського суду з прав людини в Україні</b>
                <br /> <b>Перелік питань для вивчення: </b>
                <br /> Нормативно-правова основа забезпечення виконання рішень Європейського суду з прав людини в Україні
                <br /> Організаційні засади забезпечення виконання рішень Європейського суду з прав людини в Україні в умовах активної інтеграції в Європейський союз

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Аналіз Конвенції про захист прав людини і основоположних свобод. (Джерело - Конвенція про захист прав людини і основоположних свобод (з протоколами) (Європейська конвенція з прав людини) : Конвенція, Міжнародний документ, Протокол Ради Європи
                <a href="https://zakon.rada.gov.ua/laws/show/995_004#Text" target="_blank"> від 04.11.1950</a> ).
            </p>
            <br />
            <p>
                <b>Тема 2. Конвенційна система нормативно-правового забезпечення виконання рішень Європейського суду з прав людини
                    <br /> Перелік питань для вивчення: </b>
                <br /> Становлення Конвенційної системи нормативно-правового забезпечення виконання рішень Європейського суду з прав людини. Історичний екскурс
                <br /> Основні міжнародні нормативно-правові акти, що становлять нормативно-правову основу забезпечення виконання рішень Європейського суду з прав людини, діяльність Європейського суду з прав людини, Комітету міністрів та мережі вищих судів

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Ознайомлення з Регламентом Європейського суду з прав людини (Джерело - Регламент Європейського суду з прав людини. Ради Європи. Європейський суд з прав людини. Страсбург,
                <a href="https://ips.ligazakon.net/document/SOO00263" target="_blank"> 1 червня 2010 року.</a>)
            </p>
            <br />
            <p>
                <b>Тема 3. Практика виконання рішень Європейського суду з прав людини в Україні: актуальні проблеми та перспективи їх вирішення
                    <br />Перелік питань для вивчення: </b>
                <br />Стан та перспективи імплементації європейської практики розуміння прав людини у систему правосуддя України
                <br />Актуальні проблемні питання, що виникають при забезпеченні виконання рішень Європейського суду з прав людини в Україні
                <br />Проблемні питання міжвідомчої взаємодії з питань забезпечення виконання рішень Європейського суду з прав людини в Україні

                <br /><b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /><b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Аналіз Закону України «Про виконання рішень та застосування практики Європейського суду з прав людини». (Джерело - Про виконання рішень та застосування
                практики Європейського суду з прав людини : Закон України
                <a href="https://zakon.rada.gov.ua/laws/show/3477-15#Text" target="_blank"> від 23.02.2006 № 3477-IV</a>

            </p>


            <h5 className="text-center mt-5">ОЦІНЮВАННЯ РЕЗУЛЬТАТІВ НАВЧАННЯ </h5>
            <p>

                <br />	Відвідування занять у дистанційному форматі – 90%

            </p>
            <p>
                Опрацювання обов’язкової літератури, інформаційних та інших матеріалів – 10%
            </p>
            <p>Підсумковий контроль – 0%*</p>
            <p>
                Документ про підвищення кваліфікації видається за умови відвідування занять,
                здійснення самостійної роботи, опрацювання обов’язкової літератури, інших матеріалів.
                Документ про підвищення кваліфікації видається за умови набрання учасником
                професійного навчання не менше ніж 75%, обрахованих з урахуванням питомої ваги кожного
                із критеріїв оцінювання.
            </p>
            <p className="f-italic px-5">
                *Оцінювання результатів навчання за короткостроковою програмою обсягом до 0,5
                кредиту ЄКТС відповідно до  пункту 9  розділу IV Порядку не здійснюється відповідно до
                Методичних рекомендацій щодо розроблення та оформлення програм підвищення
                кваліфікації державних службовців, голів місцевих державних адміністрацій, їх перших
                заступників та заступників, посадових осіб місцевого самоврядування та депутатів місцевих
                рад (Наказ НАДС № 38-20 від 13.03.2020р.).
            </p>

            <h5 className="text-center mt-5">ЛІТЕРАТУРА, ІНФОРМАЦІЙНІ РЕСУРСИ, ОБОВ’ЯЗКОВІ ДЛЯ ОПРАЦЮВАННЯ</h5>
            <ol>
                <li> Блажівська Н. До питання про місце рішень Європейського суду з прав людиниу національній правовій системі. Підприємництво, господарство і право. 4/2018. С. 226-230.
                </li>
                <li> Брайтонська декларація. Конференція високого рівня держав-учасниць Ради Європи 19-20.04.2012.
                    <a href="https://www.echr.coe.int/documents/d/echr/2012_Brighton_FinalDeclaration_ENG" target="_blank"> European Court of Human Right. </a>.
                </li>
                <li> Відео про процес супервізії (нагляду за виконанням рішень Європейського суду з прав людини Комітетом Міністрів Ради Європи).
                    <a href="https://www.coe.int/en/web/execution/supervision-video#{%2215024639%22:[13]}" target="_blank"> Офіційний веб-портал Європейського суду з прав людини. </a>.
                </li>

                <li> Декларація про майбутнє Європейського суду з прав людини. Конференція високого рівня держав-учасниць Ради Європи Організована за допомогою турецького
                    головування у Комітеті Міністрів Ради Європи м. Ізмір, Туреччина,
                    <a href="https://zakononline.com.ua/documents/show/316949___317014" target="_blank"> 26-27 квітня 2011 року. </a>.
                </li>
                <li> Завгородній В.А. Нормативна складова механізму імплементації рішень Європейського суду з прав людини в Україні. Правові новели. Науковий юридичний журнал. №4/2018. С.23-30.
                </li>
                <li> Інтерлакенська декларація : Рада Європи, Європейський суд з прав людини; Декларація, План, Міжнародний документ
                    <a href="https://zakon.rada.gov.ua/laws/show/994_a48#Text" target="_blank"> від 19.02.2010. </a>.
                </li>
                <li> Конвенція про захист прав людини і основоположних свобод (з протоколами) (Європейська конвенція з прав людини) : Рада Європи; Конвенція, Міжнародний документ
                    <a href="https://zakon.rada.gov.ua/laws/show/995_004" target="_blank"> Протокол від 04.11.1950 </a>.
                </li>

                <li>
                    <a href="https://unba.org.ua/assets/uploads/555174cd103632f8b66f_file.pdf" target="_blank"> Копенгагенська Декларація 2018 року </a>.
                </li>
                <li> Про виконання рішень та застосування практики Європейського суду з прав людини : Закон України
                    <a href="https://zakon.rada.gov.ua/laws/show/3477-15#Text" target="_blank"> від 23.02.2006 № 3477-IV </a>.
                </li>
                <li> Про утворення Комісії з питань виконання рішень Європейського суду з прав людини : Постанова Кабінету Міністрів України
                    <a href="https://zakon.rada.gov.ua/laws/show/258-2020-%D0%BF#Text" target="_blank"> від 01.04.2020 № 258 </a>.
                </li>
                <li> Регламент Європейського суду з прав людини. Ради Європи. Європейський суд з прав людини. Страсбург,
                    <a href="https://ips.ligazakon.net/document/SOO00263" target="_blank"> 1 червня 2010 року </a>.
                </li>
                <li>Статут Ради Європи : Рада Європи; Статут, Міжнародний документ
                    <a href="https://zakon.rada.gov.ua/laws/show/994_001#top" target="_blank"> від 05.05.1949 </a>.
                </li>
                <li>ECHR - Фільм про Європейський суд з прав людини (Ukrainian version)
                    <a href="https://www.youtube.com/watch?v=ouObIkIZRls" target="_blank"> European Court of Human Right </a>.
                </li>
                <li>Rules of the Committee of Ministers for the supervision of the execution of judgments and of the terms of friendly settlements
                    <a href="https://rm.coe.int/CoERMPublicCommonSearchServices/DisplayDCTMContent?documentId=09000016805905e5" target="_blank"> (Adopted by the Committee of Ministers on 10
                        May 2006 at the 964th meeting of the Ministers’ Deputies) </a>.
                </li>
            </ol>
        </div>,
    },
    {
        id: 'ЗК.2024.005',
        displayId: 'ЗК.2023.005',
        haveTest: false,
        durationHours: 6,
        durationDays: 2,
        credits: 0.2,
        price: 350,
        priceMembers: 0,
        themes: [
            'Тема 1. GR-менеджмент: сутність та теоретичні основи',
            'Тема 2. Технології та інструменти GR-менеджменту',
            'Тема 3. Перспективи розвитку GR-менеджменту в Україні'
        ],
        docs: [{ name: 'Презентація', value: 'https://firebasestorage.googleapis.com/v0/b/web-unapam.appspot.com/o/programs%2F5%2F%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%B0%20005.pptx?alt=media&token=b19b172a-a96e-48bf-89e4-7368dc087af7' }],
        files: [],
        name: '«Взаємодія бізнесу з органами влади (Government Relations)»',
        value: <div className="kyrs-info my-5">
            <h5 className="text-center mt-5">ПРОФІЛЬ ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={2}>1. Загальна інформація</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Назва програми  </td>
                        <td>Взаємодія бізнесу з органами влади (Government Relations)</td>
                    </tr>
                    <tr>
                        <td>Шифр програми</td>
                        <td>ЗК/2024/005 </td>
                    </tr>
                    <tr>
                        <td>Тип програми за змістом</td>
                        <td>загальна короткострокова програма підвищення кваліфікації</td>
                    </tr>
                    <tr>
                        <td>Форма навчання</td>
                        <td>дистанційна в асинхронному режимі </td>
                    </tr>
                    <tr>
                        <td>Цільова група</td>
                        <td>державні службовці, які займають посади державної служби категорій «Б» та «В»;
                            <br /> посадові особи місцевого самоврядування;
                            <br /> фахівці бізнес-структур, які забезпечують взаємодію суб’єкта господарювання з органами влади (GR-менеджери)
                        </td>
                    </tr>
                    <tr>
                        <td>Передумови навчання за програмою</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування замовника освітніх послуг у сфері професійного навчання за програмою-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування партнера (партнерів) програми</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Обсяг програми</td>
                        <td>0,2 кредит ЄКТС</td>
                    </tr>
                    <tr>
                        <td>Тривалість програми та організація навчання</td>
                        <td>загальна тривалість програми: 1 день</td>
                    </tr>
                    <tr>
                        <td>Мова(и) викладання</td>
                        <td>українська</td>
                    </tr>
                    <tr>
                        <td>Напрям(и) підвищення кваліфікації, який (які) охоплює програма</td>
                        <td>комунікація та взаємодія;  стратегічні комунікації</td>
                    </tr>
                    <tr>
                        <td>Перелік професійних компетентностей, на підвищення рівня яких спрямовано програму </td>
                        <td>знання основ GR-менеджменту, його цілей, завдань та інструментарію; основних понять «GR-менеджмент», «лобізм» і «адвокація»;
                            <br />
                            уміння застосовувати основні форми та технології лобіювання у роботі органів влади; вирішувати інтереси бізнес-структур в органах влади, використовуючи легальні інструменти GR-менеджменту;
                            <br />
                            навички налагодження ефективної взаємодії бізнес-структур та органів влади, використовуючи технології лобізму, адвокації та ін.</td>
                    </tr>
                    <tr>
                        <td>Укладач(і) програми</td>
                        <td>Кіцак Тарас Миколайович, кандидат наук з державного
                            управління, доцент, голова правління ГО «Національна
                            асоціація публічного управління та адміністрування
                            України»
                        </td>
                    </tr>

                    <tr>
                        <th className="text-center" colSpan={2}>2. Загальна мета</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Підвищення професійних компетентностей державних службовців, посадових осіб
                            місцевого самоврядування, фахівців бізнес-структур, які забезпечують взаємодію суб’єкта
                            господарювання з органами влади (GR-менеджери)</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>3. Очікувані результати навчання</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>За результатами навчання слухачі повинні демонструвати:</td>
                    </tr>
                    <tr>
                        <td>знання</td>
                        <td>основ GR-менеджменту, його цілей, завдань та інструментарію;
                            <br />
                            основних понять «GR-менеджмент», «лобізм» і «адвокація»
                        </td>
                    </tr>
                    <tr>
                        <td>уміння</td>
                        <td>застосовувати основні форми та технології лобіювання у роботі органів влади;
                            <br />
                            вирішувати інтереси бізнес-структур в органах влади, використовуючи легальні інструменти GR-менеджменту;
                        </td>
                    </tr>
                    <tr>
                        <td>навички</td>
                        <td>здатність налагоджувати ефективну взаємодію бізнес-структур та органів влади, використовуючи технології
                            лобізму, адвокації та ін.</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>4. Викладання та навчання (методи навчання, форми проведення навчальних занять)</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Дистанційне навчання через доступ до навчальних матеріалів через вебсередовище в асинхронному режимі (режимі відкладеного часу) із застосуванням електронної пошти; самостійне опрацювання рекомендованої літератури, інформаційних ресурсів. Методи навчання – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований. </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>5. Ресурсне забезпечення дистанційного навчання</th>
                    </tr>
                    <tr>
                        <td>Назви вебплатформи, вебсайту, електронної системи навчання, через які здійснюватиметься дистанційне навчання із зазначенням посилання (вебадреси)</td>
                        <td>Вебадреса: www.unapam.com <br />
                            електронна пошта: officeUNAPAM@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Назва дистанційного етапу/модуля</td>
                        <td>Тема 1 . GR-менеджмент: сутність та теоретичні основи
                            <br />  Тема 2 . Технології та інструменти GR-менеджменту
                            <br /> Тема 3. Перспективи розвитку GR-менеджменту в Україні
                        </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>6. Оцінювання і форми поточного, підсумкового контролю</th>
                    </tr>
                    <tr>
                        <td>Складові оцінювання та їх питома вага у підсумковій оцінці (%)</td>
                        <td>Відвідування занять у дистанційному форматі – 90%
                            Опрацювання обов’язкової літератури, інформаційних та
                            інших матеріалів – 10%
                            Підсумковий контроль – 0%*
                            Документ про підвищення кваліфікації видається за умови
                            набрання учасником професійного навчання не менше ніж 75
                            %, обрахованих з урахуванням питомої ваги кожного із
                            критеріїв оцінювання.</td>
                    </tr>
                    <tr>
                        <td>Форма підсумкового контролю</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>

            <p className="f-italic px-5">
                *Оцінювання результатів навчання за короткостроковою програмою обсягом до
                0,5 кредиту ЄКТС відповідно до  пункту 9  розділу IV Порядку не здійснюється
                відповідно до Методичних рекомендацій щодо розроблення та оформлення
                програм підвищення кваліфікації державних службовців, голів місцевих державних
                адміністрацій, їх перших заступників та заступників, посадових осіб місцевого
                самоврядування та депутатів місцевих рад (Наказ НАДС № 38-20 від
                13.03.2020р.).
            </p>


            <h5 className="text-center mt-5">СТРУКТУРА ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle table-responsive">
                <thead>
                    <tr>
                        <th className="text-center" rowSpan={3} >Назва теми </th>
                        <th colSpan={6}>Кількість годин </th>
                    </tr>
                    <tr>
                        <th className="text-center" rowSpan={2} >загальна кількість годин/ кредитів ЄКТС</th>
                        <th className="text-center" colSpan={6}>у тому числі: </th>

                    </tr>
                    <tr>
                        <th className="text-center" >аудиторні заняття</th>
                        <th className="text-center" >дистанційні заняття </th>
                        <th className="text-center" >навчальні візити </th>
                        <th className="text-center" >самостійна робота слухачів </th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                    </tr>
                    <tr>
                        <td>Тема 1  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>1</td>
                        <td></td>
                        <td>0,33</td>
                    </tr>
                    <tr>
                        <td>Тема 2  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>0,33</td>
                    </tr>
                    <tr>
                        <td>Тема 3  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>0,34</td>
                    </tr>
                    <tr>
                        <th> РАЗОМ </th>
                        <th>6/0,2</th>
                        <th></th>
                        <th>5/0,166</th>
                        <th></th>
                        <th>1/0,034</th>
                    </tr>
                </tbody>
            </table>


            <h5 className="text-center mt-5">ЗМІСТ ПРОГРАМИ</h5>
            <p><b>Тема 1. GR-менеджмент: сутність та теоретичні основи</b>
                <br /> <b>Перелік питань для вивчення: </b>
                <br /> Сутність поняття «GR-менеджмент»
                <br /> Цілі та завдання GR-менеджменту
                <br /> Інструменти GR-менеджменту
                <br /> Сутність понять «лобізм» і «адвокація»

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Аналіз підходів до запровадження GR-менеджменту у практиці вітчизняних суб’єктів господарювання
            </p>
            <br />
            <p>
                <b>Тема 2. Технології та інструменти GR-менеджменту
                    <br /> Перелік питань для вивчення: </b>
                <br /> Напрями налагодження ефективних комунікацій бізнесу з органами влади
                <br /> Основні форми та види лобізму
                <br /> Технології лобіювання.

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Про забезпечення участі громадськості у формуванні та реалізації державної
                політики: постанова Кабінету Міністрів України від 3 листопада 2010 р. № 996 (зі змінами і
                доповненнями) – Порядок проведення консультацій з громадськістю з питань формування та
                реалізації державної політики; Типове положення про громадську раду при міністерстві,
                іншому центральному органі виконавчої влади, Раді міністрів Автономної Республіки Крим,
                обласній, Київській та Севастопольській міській, районній, районній у мм. Києві та
                Севастополі державній адміністрації.
            </p>
            <br />
            <p>
                <b>Тема 3. Перспективи розвитку GR-менеджменту в Україні
                    <br />Перелік питань для вивчення: </b>
                <br />Аналіз законопроектів щодо лобізму в Україні - «Про лобізм» (законопроект № 5144), «Про державну реєстрацію суб’єктів лобіювання та здійснення лобіювання в
                Україні» (№ 3059), «Про лобізм» (№ 3059-1), «Про лобізм» (№ 3059-1), «Про лобістську діяльність» (№ 3059-3)
                <br />Характерні риси лобізму в Україні
                <br />Заходи щодо розвитку лобізму в Україні
                <br />Цілі GR-служби організації
                <br />GR-менеджер як фахівець, який забезпечує взаємодію суб’єкта господарювання з органами влади

                <br /><b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /><b>Перелік питань, які виносяться на самостійну роботу: </b>
                <br /> 1. Міжнародна ініціатива «Партнерство «Відкритий Уряд»
            </p>


            <h5 className="text-center mt-5">ОЦІНЮВАННЯ РЕЗУЛЬТАТІВ НАВЧАННЯ </h5>
            <p>
                <br />	Відвідування занять у дистанційному форматі – 90%
            </p>
            <p>
                Опрацювання обов’язкової літератури, інформаційних та інших матеріалів – 10%
            </p>
            <p>Підсумковий контроль – 0%*</p>
            <p>
                Документ про підвищення кваліфікації видається за умови відвідування занять,
                здійснення самостійної роботи, опрацювання обов’язкової літератури, інших матеріалів.
                Документ про підвищення кваліфікації видається за умови набрання учасником
                професійного навчання не менше ніж 75%, обрахованих з урахуванням питомої ваги кожного
                із критеріїв оцінювання.
            </p>
            <p className="f-italic px-5">
                *Оцінювання результатів навчання за короткостроковою програмою обсягом до 0,5
                кредиту ЄКТС відповідно до  пункту 9  розділу IV Порядку не здійснюється відповідно до
                Методичних рекомендацій щодо розроблення та оформлення програм підвищення
                кваліфікації державних службовців, голів місцевих державних адміністрацій, їх перших
                заступників та заступників, посадових осіб місцевого самоврядування та депутатів місцевих
                рад (Наказ НАДС № 38-20 від 13.03.2020р.).
            </p>

            <h5 className="text-center mt-5">ЛІТЕРАТУРА, ІНФОРМАЦІЙНІ РЕСУРСИ, ОБОВ’ЯЗКОВІ ДЛЯ ОПРАЦЮВАННЯ</h5>
            <ol>
                <li> Базілевич Д., Нестерович В., Федоренко В. Інститут лобіювання в Україні та за
                    кордоном : походження, проблеми, перспективи розвитку : науково-інформаційне видання. Інститут професійного лобіювання та адвокаcі. К. 2015. 105 с.
                </li>
                <li> Болотіна Є. В., Фоміченко І. П., Шашко В. О. Управлінські технології комунікативного менеджменту. БІЗНЕСІНФОРМ.
                    <a href="https://www.business-inform.net/export_pdf/business-inform-2018-9_0-pages-212_219.pdf" target="_blank"> № 9/2018. </a>
                </li>
                <li> Валенко А. Світовий досвід GR-менеджменту (21.07.2021)
                    <a href="https://yur-gazeta.com/dumka-eksperta/svitoviy-dosvid-grmenedzhmentu.html" target="_blank"> Юридична газета №14 (744) </a>
                </li>

                <li> Дюжник Д.О., Таранов М.А. Співвідношення лобізму та адвокації в контексті законодавчого регулювання. Юридичний науковий електронний журнал
                    <a href="http://www.lsej.org.ua/4_2021/26.pdf" target="_blank"> №4/2021. </a>
                </li>
                <li> Краснопольська Т. М. GR-менеджмент : навчально-методичний посібник для здобувачів другого (магістерського) рівня вищої освіти.
                    <a href="http://dspace.onua.edu.ua/bitstream/handle/11300/19110/GR-%D0%BC%D0%B5%D0%BD%D0%B5%D0%B4%D0%B6%D0%BC%D0%B5%D0%BD%D1%82.pdf?sequence=1&amp;isAllowed=y" target="_blank"> Одеса, 2022. 69 с. </a>.
                </li>
                <li> Лобіювання = корупція? Інститут лобіювання на практиці.
                    <a href="https://yur-gazeta.com/dumka-eksperta/lobiyuvannya--korupciya-institut-lobiyuvannya-na-praktici.html" target="_blank"> Юридична газета №14 (744) </a>.
                </li>
                <li> Лобіювання та лобістська діяльність в Україні: легалізована корупція чи інструмент ефективного діалогу суспільства з владою?
                    <a href="https://uifuture.org/publications/lobiyuvannya-ta-lobistska-diyalnist-v-ukrayini-legalizovana-korupcziya-chy-instrument-efektyvnogo-dialogu-suspilstva-z-vladoyu/" target="_blank"> Український інститут майбутнього,  20.07.2022 </a>.
                    (презентація).
                </li>

                <li> Лошакова Н. GR-менеджмент: які інструменти вже не допомагають українському бізнесу достукатися до влади .
                    <a href="https://delo.ua/uk/business/gr-menedzment-yaki-instrumenti-vze-ne-dopomagayut-ukrayinskomu-biznesu-dostukatisya-do-vladi-389432" target="_blank"> (02.12.2021) </a>.
                </li>
                <li> Мацкевич Р.М. Політико-правові засади формування інституту лобізму в Україні: монографія. Одеса : Фенікс, 2013. 168 с.
                </li>
                <li> Строкань О. GR-менеджмент як спосіб впливу для прийняття рішень
                    <a href="https://mitrax.com.ua/uk/blog/gr-menedzhment-kak-sposob-vliyaniya-dlya-prinyatiya-reshenij/" target="_blank"> (13.08.2020). </a>.
                </li>
                <li> GR та PR менеджмент в контексті економічної безпеки : монографія / за ред. проф. Мігус І. П. Черкаси : ПП Чабаненко Ю.А., 2014. 376 с.
                </li>
                <li>Lobbying in the 21st Century :
                    <a href="https://www.oecd-ilibrary.org/sites/c6d8eff8-en/index.html?itemId=/content/publication/c6d8eff8-en" target="_blank"> Transparency, Integrity and Access</a>.
                </li>
            </ol>
        </div>,
    },
    {
        id: 'ЗК.2024.007',
        displayId: 'ЗК.2024.007',
        haveTest: true,
        durationHours: 30,
        durationDays: 6,
        credits: 1,
        price: 0,
        priceMembers: 0,
        themes: [
            'Тема 1. Основа концепту захисту прав публічних службовців в Україні',
            'Тема 2. Права публічних службовців та правові засади їх забезпечення',
            'Тема 3. Порушення прав публічних службовців',
            'Тема 4. Проблеми захисту прав публічних службовців в Україні',
        ],
        docs: [{name: 'Презентація', value: 'https://firebasestorage.googleapis.com/v0/b/web-unapam.appspot.com/o/programs%2F7%2F%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D1%96%D1%8F_007.pptx?alt=media&token=e0964992-7504-4ed2-b724-63014bbe9be1'}],
        files: [{name: 'Витяг з наказу', value: 'https://firebasestorage.googleapis.com/v0/b/web-unapam.appspot.com/o/nakaz%2F%D0%9D%D0%B0%D0%BA%D0%B0%D0%B7_%D0%9D%D0%90%D0%94%D0%A1_03.05.2024..pdf?alt=media&token=c0b56e1a-c0dc-4690-ae13-07f9195ee67d'}],
        name: '«Захист прав публічних службовців в Україні»',
        value: <div className="kyrs-info my-5">
            <h5 className="text-center mt-5">ПРОФІЛЬ ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={2}>1. Загальна інформація</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Назва програми  </td>
                        <td>Захист прав публічних службовців в Україні</td>
                    </tr>
                    <tr>
                        <td>Шифр програми</td>
                        <td>ЗК/2024/007 </td>
                    </tr>
                    <tr>
                        <td>Тип програми за змістом</td>
                        <td>загальна короткострокова програма підвищення кваліфікації</td>
                    </tr>
                    <tr>
                        <td>Форма навчання</td>
                        <td>дистанційна в асинхронному режимі </td>
                    </tr>
                    <tr>
                        <td>Цільова група</td>
                        <td>державні службовці, які займають посади державної служби категорій «Б» та «В»;
                            <br /> посадові особи місцевого
                            самоврядування та фахівці, які забезпечують захист прав
                            публічних службовців
                        </td>
                    </tr>
                    <tr>
                        <td>Передумови навчання за програмою</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування замовника освітніх послуг у сфері професійного навчання за програмою-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування партнера (партнерів) програми</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Обсяг програми</td>
                        <td>1 кредит ЄКТС</td>
                    </tr>
                    <tr>
                        <td>Тривалість програми та організація навчання</td>
                        <td>загальна тривалість програми: 6 днів</td>
                    </tr>
                    <tr>
                        <td>Мова(и) викладання</td>
                        <td>українська</td>
                    </tr>
                    <tr>
                        <td>Напрям(и) підвищення кваліфікації, який (які) охоплює програма</td>
                        <td>дотримання прав людини та протидія дискримінації
                            загальні питання державного управління
                            зміни у законодавстві з питань державної служби</td>
                    </tr>
                    <tr>
                        <td>Перелік професійних компетентностей, на підвищення рівня яких спрямовано програму </td>
                        <td>професійні знання про права публічних службовців та їх
                            захист
                            <br />
                            професійні знання основ соціальної та правової захищеності
                            службовців</td>
                    </tr>
                    <tr>
                        <td>Укладач(і) програми</td>
                        <td>Адамська Іоліта Петрівна, доктор філософії з публічного
                            управління та адміністрування, начальник Центрального
                            міжрегіонального управління НАДС, E-mail: eolita@ukr.net
                            <br />
                            Карпа Марта Іванівна, професор, доктор наук з державного
                            управління, професор кафедри публічного управління та
                            адміністрування Університету Григорія Сковороди в
                            Переяславі, E-mail: marta.karpa@ukr.net
                        </td>
                    </tr>

                    <tr>
                        <th className="text-center" colSpan={2}>2. Загальна мета</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Підвищення професійних компетентностей державних службовців, посадових осіб
                            місцевого самоврядування та фахівців, які забезпечують захист прав публічних службовців</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>3. Очікувані результати навчання</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>За результатами навчання слухачі повинні демонструвати:</td>
                    </tr>
                    <tr>
                        <td>знання</td>
                        <td>про права, свободи та обов’язки людини і громадянина,

                            засади запобігання та протидії дискримінації;
                            <br />  основ соціальної та правової захищеності;
                            <br />  у сфері забезпечення рівних прав та можливостей жінок
                            і чоловіків;
                            <br /> про основи захисту прав публічних службовців
                        </td>
                    </tr>
                    <tr>
                        <td>уміння</td>
                        <td>ефективної комунікації з питань захисту прав
                            публічних службовців;
                            <br />  підтримувати розвиток лідерського потенціалу
                            публічної служби
                        </td>
                    </tr>
                    <tr>
                        <td>навички</td>
                        <td>реалізації та захисту прав публічних службовців;
                            <br />   процедурного вирішення питань щодо захисту прав
                            публічних службовців</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>4. Викладання та навчання (методи навчання, форми проведення навчальних занять)</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Дистанційне навчання через доступ до навчальних матеріалів через вебсередовище в
                            асинхронному режимі (режимі відкладеного часу) із застосуванням електронної пошти;
                            самостійне опрацювання рекомендованої літератури, інформаційних ресурсів. Методи
                            навчання – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу,
                            системного підходу, інтегрований.</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>5. Ресурсне забезпечення дистанційного навчання</th>
                    </tr>
                    <tr>
                        <td>Назви вебплатформи, вебсайту, електронної системи навчання, через які здійснюватиметься дистанційне навчання із зазначенням посилання (вебадреси)</td>
                        <td>Вебадреса: www.unapam.com <br />
                            електронна пошта: officeUNAPAM@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Назва дистанційного етапу/модуля</td>
                        <td>Тема 1. Основа концепту захисту прав публічних службовців в Україні
                            <br /> Тема 2. Права публічних службовців та правові засади їх забезпечення
                            <br /> Тема 3. Порушення прав публічних службовців
                            <br /> Тема 4. Проблеми захисту прав публічних службовців в Україні
                        </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>6. Оцінювання і форми поточного, підсумкового контролю</th>
                    </tr>
                    <tr>
                        <td>Складові оцінювання та їх питома вага у підсумковій оцінці (%)</td>
                        <td>Відвідування занять у дистанційному форматі – 40%
                            Опрацювання обов’язкової літератури, інформаційних та
                            інших матеріалів – 10%
                            Підсумковий контроль – 50%
                            Документ про підвищення кваліфікації видається за умови
                            набрання учасником професійного навчання не менше ніж
                            70%, обрахованих з урахуванням питомої ваги кожного із
                            критеріїв оцінювання.</td>
                    </tr>
                    <tr>
                        <td>Форма підсумкового контролю</td>
                        <td>комп’ютерне тестування</td>
                    </tr>
                </tbody>
            </table>




            <h5 className="text-center mt-5">СТРУКТУРА ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle table-responsive">
                <thead>
                    <tr>
                        <th className="text-center" rowSpan={3} >Назва теми </th>
                        <th colSpan={6}>Кількість годин </th>
                    </tr>
                    <tr>
                        <th className="text-center" rowSpan={2} >загальна кількість годин/ кредитів ЄКТС</th>
                        <th className="text-center" colSpan={6}>у тому числі: </th>

                    </tr>
                    <tr>
                        <th className="text-center" >аудиторні заняття</th>
                        <th className="text-center" >дистанційні заняття </th>
                        <th className="text-center" >навчальні візити </th>
                        <th className="text-center" >самостійна робота слухачів </th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                    </tr>
                    <tr>
                        <td>Тема 1  </td>
                        <td>6/0,20</td>
                        <td></td>
                        <td>4</td>
                        <td></td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Тема 2  </td>
                        <td>8/0,27</td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Тема 3  </td>
                        <td>7/0,23</td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Тема 4  </td>
                        <td>7/0,23</td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Підсумковий
                            контроль результатів
                            навчання  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <th> РАЗОМ </th>
                        <th>30/1,00</th>
                        <th></th>
                        <th>24</th>
                        <th></th>
                        <th>6</th>
                    </tr>
                </tbody>
            </table>


            <h5 className="text-center mt-5">ЗМІСТ ПРОГРАМИ</h5>
            <p><b>Тема 1. Основа концепту захисту прав публічних службовців в Україні</b>
                <br /> <b>Перелік питань для вивчення: </b>
                <br /> Поняття публічного службовця та захисту його прав у площині співвідношення з
                поняттями «державної служби», «служби в органах місцевого самоврядування»
                <br /> Нормативно-правові основи захисту прав публічних службовців в Україні
                <br /> Принципи, суттєві ознаки та динаміка розвитку концепту захисту прав публічних
                службовців в Україні

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють
                    розкрити зміст теми: лекція (дистанційна асинхронна форма).</b>
                <br /> <b>Перелік питань, які виносяться на самостійну роботу учасників професійного
                    навчання:</b>
                <br /> 1. Ознайомлення з особливостями захисту прав публічних службовців відповідно
                до норм спеціальних законів, які визначають діяльність окремих державних органів
                (наприклад: «Про прокуратуру», «Про Державне бюро розслідувань», «Про судоустрій і
                статус суддів», «Про Національне антикорупційне бюро України», «Про дипломатичну
                службу», «Про Національну поліцію» та ін.).
            </p>
            <br />
            <p>
                <b>Тема 2. Права публічних службовців та правові засади їх забезпечення
                    <br /> Перелік питань для вивчення: </b>
                <br /> Права як складова правового статусу публічних службовців
                <br />Права публічних службовців: основні види та їх особливості
                <br />Основні права публічних службовців щодо соціального захисту
                <br />Правові засади забезпечення прав публічного службовця

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекція (дистанційна асинхронна форма).
                <br /> <b>Перелік питань, які виносяться на самостійну роботу учасників професійного
                    навчання: </b>
                <br /> 1. Перегляд освітнього серіалу «Публічна служба. Хто такі публічні службовці,
                чим вони займаються та як взаємодіяти з ними». Джерело: Освітній серіал. Веб-портал «Дія.
                Освіта». URL: https://osvita.diia.gov.ua/courses/public-service .
            </p>
            <br />
            <p>
                <b>Тема 3. Порушення прав публічних службовців
                    <br />Перелік питань для вивчення: </b>
                <br /> Види та структура характерних порушень прав державних службовців
                <br />  Порушення прав на публічну службу, її проходження та припинення
                <br />  Порушення прав публічних службовців у контексті забезпечення службової
                дисципліни
                <br />  Процедурні питання щодо фіксації порушення прав

                <br /><b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекція (дистанційна асинхронна форма).
                <br /><b>Перелік питань, які виносяться на самостійну роботу учасників професійного навчання: </b>
                <br /> 1. Ознайомлення з розробкою «Алгоритм захисту права на державну службу
                (коментар)». Джерело: Національне агентство України з питань державної служби. URL:
                https://nads.gov.ua/alhorytm-zakhystu-prava-na-derzhavnu-sluzhbu-komentar?v=62d7fab00c6c5 .
            </p>

            <br />
            <p>
                <b>Тема 4. Проблеми захисту прав публічних службовців в Україні
                    <br />Перелік питань для вивчення: </b>
                <br /> Особливості захисту прав публічних службовців України
                <br /> Поетапний порядок дій щодо подання скарг з питань порушення прав
                публічних службовців
                <br />  Розгляд скарг з питань порушення прав публічних службовців
                <br />  Удосконалення та новітні технології захисту прав службовців в контексті розвитку
                лідерського потенціалу публічної служби України

                <br /><b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекція (дистанційна асинхронна форма).
                <br /><b>Перелік питань, які виносяться на самостійну роботу учасників професійного навчання: </b>
                <br /> 1. Ознайомлення з методичними рекомендаціями щодо дій у разі можливого
                порушення наданих Законом України &quot;Про державну службу&quot; прав або виникнення
                перешкод у реалізації права на державну службу. Джерело: Про затвердження Методичних
                рекомендацій щодо дій у разі можливого порушення наданих Законом України &quot;Про
                державну службу&quot; прав або виникнення перешкод у реалізації права на державну службу (за
                статтею 11 Закону України &quot;Про державну службу&quot;). НАДС; Наказ, Рекомендації
                від 08.11.2023 № 180-23. URL : https://zakon.rada.gov.ua/rada/show/v0180859-23#Text .
            </p>


            <h5 className="text-center mt-5">ОЦІНЮВАННЯ РЕЗУЛЬТАТІВ НАВЧАННЯ </h5>
            <p>
                Оцінювання результатів навчання здійснюється за результатами вхідного, поточного
                та підсумкового контролю:
                <br />  Знання слухачів оцінюється за 100 бальною шкалою на основі:
                <br /> - відвідування занять – 40% (оцінюється у 40 балів)
                <br /> - опрацювання обов’язкової літератури, інших матеріалів у вигляді опитування – 10%
                (оцінюється у 10 балів)
                <br /> - підсумкового контролю у вигляді комп’ютерного тестування 50% (оцінюється у 50
                балів).
                <br />  Документ про підвищення кваліфікації видається за умови набрання учасником
                професійного навчання не менше ніж 70% (тобто 70 балів), обрахованих з урахуванням
                питомої ваги кожного із критеріїв оцінювання.
                <br /> Форма перевірки проходить через здійснення учасником професійного навчання
                реєстрації на програму, фіксації часу роботи з матеріалами програми, перевірки
                підсумкового контролю у вигляді комп’ютерного тестування за допомогою комп’ютерної
                програми. Оформлення результатів проходження програми відбувається методом сумування
                усіх складових оцінювання.
            </p>

            <h5 className="text-center mt-5">ЛІТЕРАТУРА, ІНФОРМАЦІЙНІ РЕСУРСИ, ОБОВ’ЯЗКОВІ ДЛЯ ОПРАЦЮВАННЯ. ПЕРЕЛІК НОРМАТИВНО-ПРАВОВИХ

                АКТІВ</h5>
            <ol>

                <li> Адамська І. П. Права публічних службовців як основа їх правого статусу.
                    <a href="http://nbuv.gov.ua/UJRN/DeVr_2020_2_3" target="_blank"> Демократичне врядування. 2020. Вип. 2. </a>
                </li>
                <li>Алгоритм захисту права на державну службу (коментар).
                    <a href="https://nads.gov.ua/alhorytm-zakhystu-prava-na-derzhavnu-sluzhbu-komentar?v=62d7fab00c6c5." target="_blank"> Національне агентство
                        України з питань державної служби. </a>
                </li>

                <li>Алюшина Н. О. Дослідження проявів цькування на державній службі.
                    <a href="https://doi.org/10.33990/2070-4011.59.2019.178431." target="_blank">  Ефективність
                        державного управління. 2019. Вип. 2. С. 149-162. </a>
                </li>
                <li> Карпа М. І., Кіцак Т. М. Статусні характеристики публічних службовців як
                    передумова становлення та захисту їх прав. Інвестиції: практика та досвід. 2023.
                    №7. С. 100–106.
                </li>
                <li> Кодекс адміністративного судочинства України : Кодекс України;
                    <a href="https://zakon.rada.gov.ua/laws/show/2747-15#Text." target="_blank"> Закон від
                        06.07.2005 №2747-IV. </a>.
                </li>
                <li> Кодекс законів про працю України:
                    <a href="https://zakon.rada.gov.ua/laws/show/322-08#Text." target="_blank"> Кодекс від 10.12.1971 № 322-VIII. </a>
                    (презентація).
                </li>

                <li> Конституція України : Конституція,
                    <a href="https://zakon.rada.gov.ua/laws/show/254%D0%BA/96-%D0%B2%D1%80#Text" target="_blank"> Закон від 28.06.1996 №254к/96-ВР </a>.
                </li>

                <li>Національне агентство України з питань державної служби.
                    <a href="https://nads.gov.ua/misiya-nads ." target="_blank"> Офіційний сайт. </a>.
                </li>
                <li>Про державну службу : Закон України
                    <a href="https://zakon.rada.gov.ua/laws/show/889-19#Text." target="_blank"> від 10.12.2015 №889-VIII.</a>
                </li>
                <li> Про затвердження Методичних рекомендацій щодо дій у разі можливого порушення
                    наданих Законом України &quot;Про державну службу&quot; прав або виникнення перешкод у
                    реалізації права на державну службу (за статтею 11 Закону України &quot;Про державну
                    службу&quot;). НАДС; Наказ,
                    <a href="https://zakon.rada.gov.ua/rada/show/v0180859-23#Text." target="_blank"> Рекомендації від 08.11.2023 № 180-23.</a>
                </li>
                <li> Про службу в органах місцевого самоврядування : Закон України
                    <a href="https://zakon.rada.gov.ua/laws/show/3077-20#Text." target="_blank"> від 02.05.2023 №3077-IX.</a>
                </li>
                <li>  Публічна служба. Хто такі публічні службовці, чим вони займаються та як
                    взаємодіяти з ними. Освітній серіал.
                    <a href="https://osvita.diia.gov.ua/courses/public-service." target="_blank">  Веб-портал «Дія. Освіта».</a>
                </li>
            </ol>
        </div>,
    },
    {
        id: 'ЗК.2024.008',
        displayId: 'ЗК.2024.008',
        haveTest: false,
        durationHours: 30,
        durationDays: 6,
        credits: 1,
        price: 350,
        priceMembers: 0,
        themes: [
            'Тема 1. Стратегічна роль євроатлантичної інтеграції та її вплив на управління військовою освітою',
            'Тема 2. Структурно-управлінські аспекти системи військової освіти в Україні',
            'Тема 3. Основи менеджменту військової освіти за стандартами НАТО',
            'Тема 4. Формування та реалізація державної політики з питань системи військової освіти',
        ],
        docs: [{ name: 'Презентація', value: 'https://firebasestorage.googleapis.com/v0/b/web-unapam.appspot.com/o/programs%2F8%2F%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F_008.pptx?alt=media&token=474c3fc8-5614-4e17-aad4-b5876f573022'}],
        files: [],
        name: '«Вдосконалення системи військової освіти в Україні за стандартами НАТО: публічно-управлінський аспект»',
        value: <div className="kyrs-info my-5">
            <h5 className="text-center mt-5">ПРОФІЛЬ ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={2}>1. Загальна інформація</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Назва програми  </td>
                        <td>Вдосконалення системи військової освіти в Україні за стандартами НАТО: публічно-управлінський аспект</td>
                    </tr>
                    <tr>
                        <td>Шифр програми</td>
                        <td>ЗК/2024/008 </td>
                    </tr>
                    <tr>
                        <td>Тип програми за змістом</td>
                        <td>загальна короткострокова програма підвищення кваліфікації</td>
                    </tr>
                    <tr>
                        <td>Форма навчання</td>
                        <td>дистанційна в асинхронному режимі </td>
                    </tr>
                    <tr>
                        <td>Цільова група</td>
                        <td>державні службовці, які займають посади державної служби
                            категорій «Б» та «В», посадові особи місцевого
                            самоврядування, державні службовці Генерального штабу
                            Збройних Сил України, державні службовці апарату
                            Міністерства оборони України та Збройних Сил України,
                            науково-педагогічний склад підпорядкованих ВВНЗ, ВНП
                            ЗВО та наукових установ, військові та інші фахівці, які
                            забезпечують вдосконалення системи військової освіти в
                            Україні за стандартами НАТО, планування, координацію,
                            моніторинг, інформаційну комунікацію та іншу професійну
                            діяльність у сфері євроатлантичної інтеграції та розвитку
                            системи військової освіти в Україні
                        </td>
                    </tr>
                    <tr>
                        <td>Передумови навчання за програмою</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування замовника освітніх послуг у сфері професійного навчання за програмою-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Найменування партнера (партнерів) програми</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Обсяг програми</td>
                        <td>1 кредит ЄКТС</td>
                    </tr>
                    <tr>
                        <td>Тривалість програми та організація навчання</td>
                        <td>загальна тривалість програми: 6 днів</td>
                    </tr>
                    <tr>
                        <td>Мова(и) викладання</td>
                        <td>українська</td>
                    </tr>
                    <tr>
                        <td>Напрям(и) підвищення кваліфікації, який (які) охоплює програма</td>
                        <td>євроатлантична інтеграція</td>
                    </tr>
                    <tr>
                        <td>Перелік професійних компетентностей, на підвищення рівня яких спрямовано програму </td>
                        <td>професійні знання про політику у сфері євроатлантичної інтеграції
                            <br />   професійні знання щодо формування та реалізація державної
                            <br />    політики з питань системи військової освіти
                            <br /> комунікація та взаємодія</td>
                    </tr>
                    <tr>
                        <td>Укладач(і) програми</td>
                        <td>Акімова Людмила Миколаївна, заступник Голови
                            Національного агентства кваліфікацій, доктор наук з
                            державного управління, професор, Заслужений працівник
                            освіти України, e-mail: l_akimova@ukr.net
                            <br /> <br />
                            Ванкевич Петро Іванович, доктор технічних наук, професор,
                            Національна академія сухопутних військ імені гетьмана Петра
                            Сагайдачного, працівник ЗСУ, лауреат Національної премії
                            України імені Бориса Патона, e-mail: dedykto@ukr.net
                            <br /> <br />
                            Карпа Марта Іванівна, професор, доктор наук з державного
                            управління, професор кафедри публічного управління та
                            адміністрування Університету Григорія Сковороди в
                            Переяславі, e-mail: marta.karpa@ukr.net
                            <br /> <br />
                            Тимочко Іван Олексійович, голова Ради резервістів
                            Сухопутних військ Збройних Сил України, e-mail:
                            tymochkoivan1@gmail.com
                        </td>
                    </tr>

                    <tr>
                        <th className="text-center" colSpan={2}>2. Загальна мета</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Підвищення професійних компетентностей державних службовців, посадових осіб
                            місцевого самоврядування, державних службовців Генерального штабу Збройних Сил
                            України, державних службовців апарату Міністерства оборони України та Збройних Сил
                            України та науково-педагогічного складу підпорядкованих ВВНЗ, ВНП ЗВО та наукових
                            установ, військових та інших фахівців, які забезпечують вдосконалення системи військової
                            освіти в Україні за стандартами НАТО, планування, координацію, моніторинг,
                            інформаційну комунікацію та іншу професійну діяльність у сфері євроатлантичної
                            інтеграції та розвитку системи військової освіти в Україні</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>3. Очікувані результати навчання</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>За результатами навчання слухачі повинні демонструвати:</td>
                    </tr>
                    <tr>
                        <td>знання</td>
                        <td>про політику у сфері євроатлантичної інтеграції;
                            <br />  про державну політику у сфері військової освіти;
                            <br />    знання у сфері міжнародної взаємодії;
                            <br />    щодо міжвідомчої взаємодії у сфері військової освіти;
                            <br />  основ менеджменту військової освіти за стандартами
                            НАТО
                        </td>
                    </tr>
                    <tr>
                        <td>уміння</td>
                        <td>використовувати нормативно-правові документи в
                            працювати з інформацією у сфері військової освіти;
                            <br />  вміння вирішувати комплексні завдання
                        </td>
                    </tr>
                    <tr>
                        <td>навички</td>
                        <td>реалізації державної політики з питань системи військової освіти;
                            <br />  управління службовою кар’єрою на основі принципів та засад, прийнятих у державах-членах НАТО;
                            <br />  формування безперервного освітнього та професійного
                            розвитку військових фахівців упродовж військової кар’єри</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>4. Викладання та навчання (методи навчання, форми проведення навчальних занять)</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>Дистанційне навчання через доступ до навчальних матеріалів через вебсередовище в
                            асинхронному режимі (режимі відкладеного часу) із застосуванням електронної пошти;
                            самостійне опрацювання рекомендованої літератури, інформаційних ресурсів. Методи
                            навчання – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу,
                            системного підходу, інтегрований.</td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>5. Ресурсне забезпечення дистанційного навчання</th>
                    </tr>
                    <tr>
                        <td>Назви вебплатформи, вебсайту, електронної системи навчання, через які здійснюватиметься дистанційне навчання із зазначенням посилання (вебадреси)</td>
                        <td>Вебадреса: www.unapam.com <br />
                            електронна пошта: officeUNAPAM@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Назва дистанційного етапу/модуля</td>
                        <td>Тема 1. Стратегічна роль євроатлантичної інтеграції та її вплив на управління військовою освітою.
                            <br /> Тема 2. Структурно-управлінські аспекти системи військової освіти в Україні.
                            <br /> Тема 3. Основи менеджменту військової освіти за стандартами НАТО.
                            <br /> Тема 4. Формування та реалізація державної політики з питань системи військової освіти.
                        </td>
                    </tr>
                    <tr>
                        <th className="text-center" colSpan={2}>6. Оцінювання і форми поточного, підсумкового контролю</th>
                    </tr>
                    <tr>
                        <td>Складові оцінювання та їх питома вага у підсумковій оцінці (%)</td>
                        <td>Відвідування занять у дистанційному форматі – 90%
                            <br /> Опрацювання обов’язкової літератури, інформаційних та
                            інших матеріалів – 10%
                            <br />  Документ про підвищення кваліфікації видається за умови
                            набрання учасником професійного навчання не менше ніж
                            70%, обрахованих з урахуванням питомої ваги кожного із
                            критеріїв оцінювання.</td>
                    </tr>

                </tbody>
            </table>




            <h5 className="text-center mt-5">СТРУКТУРА ПРОГРАМИ</h5>
            <table className="my-table table table-bordered align-middle table-responsive">
                <thead>
                    <tr>
                        <th className="text-center" rowSpan={3} >Назва теми </th>
                        <th colSpan={6}>Кількість годин </th>
                    </tr>
                    <tr>
                        <th className="text-center" rowSpan={2} >загальна кількість годин/ кредитів ЄКТС</th>
                        <th className="text-center" colSpan={6}>у тому числі: </th>

                    </tr>
                    <tr>
                        <th className="text-center" >аудиторні заняття</th>
                        <th className="text-center" >дистанційні заняття </th>
                        <th className="text-center" >навчальні візити </th>
                        <th className="text-center" >самостійна робота слухачів </th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                    </tr>
                    <tr>
                        <td>Тема 1  </td>
                        <td>6/0,20</td>
                        <td></td>
                        <td>4</td>
                        <td></td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Тема 2  </td>
                        <td>8/0,27</td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Тема 3  </td>
                        <td>7/0,23</td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Тема 4  </td>
                        <td>7/0,23</td>
                        <td></td>
                        <td>6</td>
                        <td></td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Підсумковий
                            контроль результатів
                            навчання  </td>
                        <td>2/0,067</td>
                        <td></td>
                        <td>2</td>
                        <td></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <th> РАЗОМ </th>
                        <th>30/1,00</th>
                        <th></th>
                        <th>24</th>
                        <th></th>
                        <th>6</th>
                    </tr>
                </tbody>
            </table>


            <h5 className="text-center mt-5">ЗМІСТ ПРОГРАМИ</h5>
            <p><b>Тема 1. Стратегічна роль євроатлантичної інтеграції та її вплив на управління військовою освітою</b>
                <br /> <b>Перелік питань для вивчення: </b>
                <br /> Актуальність, завдання, заходи трансформації системи військової освіти відповідно
                до стандартів НАТО
                <br />  Євроатлантична інтеграція та інституціоналізація управління військовою освітою
                <br /> Актуальні проблеми трансформації військової освітньої галузі в умовах
                синхронізації зі стандартами НАТО

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють
                    розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-
                демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу,
                інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу учасників професійного
                    навчання:</b>
                <br /> 1. Ознайомлення з Постановою Кабінету Міністрів України «Про трансформацію
                системи військової освіти» від 15.12.1997 №1410. URL.
                https://zakon.rada.gov.ua/laws/show/1410-97-%D0%BF#Text .
            </p>
            <br />
            <p>
                <b>Тема 2. Структурно-управлінські аспекти системи військової освіти в Україні.
                    <br /> Перелік питань для вивчення: </b>
                <br /> Органи управління у сфері військової освіти та їх діяльність
                <br /> Роль державних органів та органів місцевого самоврядування, до сфери управління
                яких належать заклади спеціалізованої освіти військового спрямування у системі військової
                освіти в Україні
                <br /> Структурно-управлінські особливості впровадження Концепції трансформації
                системи військової освіти відповідно до критеріїв НАТО

                <br /> <b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-
                демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу,
                інтегрований.
                <br /> <b>Перелік питань, які виносяться на самостійну роботу учасників професійного
                    навчання: </b>
                <br /> 1. Ознайомлення з впровадженням короткострокових курсів з питань планування
                бойових дій відповідно до процедур TLP та MDMP за стандартами НАТО на тактичному рівні
                військової освіти у вищих військових навчальних закладах та військових навчальних підрозділах
                закладів вищої освіти. Розвиток процедур НАТО щодо планування та прийняття військових
                рішень.
            </p>
            <br />
            <p>
                <b>Тема 3. Основи менеджменту військової освіти за стандартами НАТО
                    <br />Перелік питань для вивчення: </b>
                <br />  Менеджмент системи військової освіти відповідно до доктринальних принципів НАТО
                <br />  Система управління службовою кар’єрою на основі принципів та засад, прийнятих у
                державах-членах НАТО
                <br />  Новий стиль, мотивація та військове лідерство як інновації у професійних
                стандартах підготовки військових фахівців
                <br />  Пріоритетні завдання у сфері військової освіти з питань євроатлантичної інтеграції
                України для посадових осіб державних органів, установ та організацій, безпосередньо
                залучених до виконання заходів євроатлантичної інтеграції

                <br /><b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b>лекційно-презентаційне заняття; методи – ілюстративно-демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /><b>Перелік питань, які виносяться на самостійну роботу учасників професійного навчання: </b>
                <br /> 1. Ознайомлення з динамікою розвитку тренувальних місій НАТО: JMTG-U –
                «Об’єднана багатонаціональна група з підготовки – Україна», тренувальна місія збройних
                сил Канади UNIFIER, Тренувальна місія збройних сил Литовської Республіки LMTG-U,
                Операція збройних сил Великої Британії та Королівства Данії ORBITAL.
            </p>

            <br />
            <p>
                <b>Тема 4. Формування та реалізація державної політики з питань системи військової освіти
                    <br />Перелік питань для вивчення: </b>
                <br /> Спроможність організовувати і здійснювати заходи щодо військової освіти органів
                державної влади, органів місцевого самоврядування та органів військового управління
                <br />   Координація та міжвідомча координація діяльності органів державної влади, органів
                місцевого самоврядування та органів військового управління щодо підготовки військових
                фахівців з питань оборони
                <br /> Державна політика щодо формування безперервного освітнього та професійного
                розвитку військових фахівців упродовж військової кар’єри. Впровадження багаторівневої
                системи L-курсів для офіцерського складу
                <br />  Перспективи та стратегічні напрями державної політики щодо впровадження
                стандартів НАТО, включення їх у комунікаційні плани відповідних державних органів,
                установ та організацій, інформування цільових аудиторій про хід реалізації таких проектів

                <br /><b>Форма проведення навчальних занять та методи навчання, які дозволяють розкрити зміст теми:</b> лекційно-презентаційне заняття; методи – ілюстративно-
                демонстративний, проблемно-пошуковий, критичного аналізу, системного підходу, інтегрований.
                <br /><b>Перелік питань, які виносяться на самостійну роботу учасників професійного навчання: </b>
                <br /> 1. Мета, перспективи, процеси та результати реалізації багаторівневої системи L-
                курсів для професійного розвитку військових фахівців упродовж військової кар’єри.
            </p>


            <h5 className="text-center mt-5">ОЦІНЮВАННЯ РЕЗУЛЬТАТІВ НАВЧАННЯ </h5>
            <p>
                Оцінювання результатів навчання здійснюється за результатами вхідного, поточного
                та підсумкового контролю:
                <br />  Знання слухачів оцінюється за 100 бальною шкалою на основі:
                <br /> - відвідування занять – 90% (оцінюється у 90 балів)
                <br /> - опрацювання обов’язкової літератури, інших матеріалів у вигляді опитування – 10%
                (оцінюється у 10 балів)

                <br />  Документ про підвищення кваліфікації видається за умови набрання учасником
                професійного навчання не менше ніж 70% (тобто 70 балів), обрахованих з урахуванням
                питомої ваги кожного із критеріїв оцінювання.
                <br /> Форма перевірки проходить через здійснення учасником професійного навчання
                реєстрації на програму, фіксації часу роботи з матеріалами програми, перевірки
                підсумкового контролю у вигляді комп’ютерного тестування за допомогою комп’ютерної
                програми. Оформлення результатів проходження програми відбувається методом сумування
                усіх складових оцінювання.
            </p>

            <h5 className="text-center mt-5">ЛІТЕРАТУРА, ІНФОРМАЦІЙНІ РЕСУРСИ, ОБОВ’ЯЗКОВІ ДЛЯ ОПРАЦЮВАННЯ. ПЕРЕЛІК НОРМАТИВНО-ПРАВОВИХ

                АКТІВ</h5>
            <ol>

                <li> Північноатлантичний договір : НАТО; Договір, Пакт,
                    <a href="https://zakon.rada.gov.ua/laws/show/950_008#Text." target="_blank"> Міжнародний документ від 04.04.1949. </a>
                </li>
                <li>Фільм «НАТО: свій чи чужий?».
                    <a href="https://www.youtube.com/watch?v=cufR0KpJZ_Q&amp;list=PLS1g-GgeUlij8qeJo6xA6lbQ5XzpD-x13&amp;index=7" target="_blank">  Медіа Центр «Розмай». 2007 </a>
                </li>

                <li>Про трансформацію системи військової освіти :
                    <a href="https://zakon.rada.gov.ua/laws/show/1410-97-%D0%BF#Text" target="_blank">  Постанова Кабінету Міністрів України 15.12.1997 №1410.</a>
                </li>

                <li> Про організацію та проведення курсів професійної військової освіти та курсів
                    підвищення кваліфікації у вищих військових навчальних закладах, військових
                    навчальних підрозділах закладів вищої освіти і закладах фахової передвищої
                    військової освіти у 2023-2024 роках : Міноборони України; Наказ,
                    <a href="https://zakon.rada.gov.ua/rada/show/v0007322-23#Text." target="_blank"> Форма типового
                        документа, План [...] від 04.01.2023 №7. </a>.
                </li>
                <li> Про затвердження Положення про Міністерство оборони України : Постанова
                    Кабінету Міністрів України;
                    <a href="https://zakon.rada.gov.ua/laws/show/671-2014-%D0%BF#Text ." target="_blank">  Положення від 26.11.2014 №671.</a>
                    (презентація).
                </li>

                <li> Про Стратегію комунікації з питань євроатлантичної інтеграції України на період до 2025 року : Указ Президента України;
                    <a href="https://zakon.rada.gov.ua/laws/show/348/2021#Text." target="_blank"> Стратегія від 11.08.2021 №348/2021.</a>
                </li>

                <li>Про схвалення Стратегії розвитку вищої освіти в Україні на 2022-2032 роки :
                    Розпорядження Кабінету Міністрів України;
                    <a href="https://zakon.rada.gov.ua/laws/show/286-2022-%D1%80#n12." target="_blank">  Стратегія від 23.02.2022 №286-р.</a>
                </li>
                <li>Стандарти НАТО. Журнал Ukraine to NATO.
                    <a href="https://ukrainetonato.com.ua/standarty-nato/" target="_blank"> Офіційний веб-портал.</a>
                </li>
                <li> Перелік завдань і заходів з реалізації Концепції трансформації системи військової
                    освіти. Додаток в редакції
                    <a href="https://zakon.rada.gov.ua/laws/show/1410-97-%D0%BF#n413." target="_blank"> Постанови КМ №1490 від 30.12.2022.</a>
                </li>
                <li> Про затвердження Положення про вищі військові навчальні заклади : Постанова
                    Кабінету Міністрів України;
                    <a href="https://zakon.rada.gov.ua/laws/show/467-2021-%D0%BF#Text." target="_blank"> Положення від 12.05.2021 №467.</a>
                </li>
                <li> Про Стратегію національної безпеки України : Указ Президента України
                    <a href="https://zakon.rada.gov.ua/laws/show/392/2020#n7." target="_blank">  № 392/2020 від 14 вересня 2020 року.</a>
                </li>
            </ol>
        </div>,
    }
]





export const getKyrses = () => Kyrses;

export const getKyrsById = (id: string) => Kyrses.find((k) => k.id == id)
