import { useContext, useEffect, useState } from "react"
import './dashboard.css';
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { Applications } from "./Pages/Applications";

import { getAdminMenu } from "../../services/local-data/admin-menu";
import { AdminMenuT } from "../../types/AdnimMenu";
import { Example } from "../../components/Chart/Chart2";
import { AuthContext } from "../../core/auth";
import logo from '../../asset/logo.png'


const LayoutAdmin = (props: any) => {
  const [activeTab, setActiveTab] = useState<string>('-1');
  const [menu, setMenu] = useState<AdminMenuT | []>([]);
  const { user } = useContext(AuthContext);

  useEffect(() => { window.scrollTo(0, 0) }, []);

  useEffect(() => {
    const _menu = getAdminMenu()
    _menu && setMenu(_menu);
    setActiveMenu(_menu);
  }, []);

  const setActiveMenu = (menu: AdminMenuT) => {
    const url = window.location.href;
    const activeTab = url.substring(url.indexOf("/admin"));
    let active = '-1';
    menu.forEach((m, i: number) => {
      if (m.link == activeTab) {
        active = `${i}`;
      } else {
        m.subItems?.forEach((s, ii: number) => {
          if (s.link == activeTab) { active = `${i}-${ii}`; }
        })
      }
    })
    setActiveTab(`${active}`)
  }

  useEffect(() => {
    document.getElementById('dismiss')?.addEventListener('click', (e: any) => {
      const navbar: any = document.getElementById('sidenav')
      navbar.classList.remove('open');
    })
  }, [])

  const showMenu = () => {
    const menu: any = document.querySelector('.menu')
    menu && menu.classList.toggle('open');
  }

  if (user && user?.role !== 'publisher') {
    return <Redirect to="/" />;
  }

  return (
    <div className="mx-4  dashboard">

      <div className="app">
        <div id="sidenav">
          <div className="wrapper">
            <div className="logo">
              <div className="log-out">

          <NavLink to='/' >  <i title="Вихід" className="far fa-hand-point-left"/></NavLink>
              </div>

              < NavLink onClick={() => setActiveTab('-1')} to='/admin' > <img src={logo} alt="" />   </NavLink>
              <span onClick={() => showMenu()} className="nav-icon pull-right"><i className="fa fa-bars"></i></span>
            </div>
            <div className="menu">
              <ul>
                {menu?.map((m: any, i: number) =>
                  <li key={i} >
                    <div className={`${activeTab == `${i}` ? "active" : ''}`} onClick={() => setActiveTab(`${i}`)}>
                      <NavLink to={`${m.link}`} >
                        {m.icon && <i className={m.icon} />}
                        {m?.name}
                      </NavLink>
                    </div>
                    {m.subItems && <ul>
                      {m.subItems.map((menu: any, ii: number) =>
                        <li key={ii} >
                          <div className={`${activeTab == `${i}-${ii}` ? "active" : ''}`} onClick={() => setActiveTab(`${i}-${ii}`)}>
                            <NavLink to={`${menu.link}`} >
                              {menu?.name} {menu.icon && <i className={menu.icon} />}
                            </NavLink>
                          </div>
                        </li>
                      )}
                    </ul>}
                  </li>
                )}

              </ul>
            </div>
          </div>
        </div>

        <div id="dash-content">
          {props.children}
        </div>
      </div>

    </div >
  )
}
export default withRouter(LayoutAdmin);