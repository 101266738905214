import 'react-toastify/dist/ReactToastify.min.css';
import './notification.css';
import { ToastContainer, toast } from 'react-toastify';


export const NotificationSvc = () => {

    const notifications = () => <ToastContainer
        position="top-right"
        autoClose={ 5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
    />

    const onSuccess = (message: string) => toast.success(message);
    const onInfo = (message: string) => toast.info(message);
    const onWarning = (message: string, autoClose: number = 5000) => toast.warn(message, { autoClose: autoClose, });
    const onError = (message: string, error?: Error | any) => toast.error(`${message} ${error?.message || error}`);

    return { notifications, onSuccess, onInfo, onWarning, onError }
}