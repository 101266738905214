import { useContext, useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AuthContext } from "../../../core/auth";
import { getAllTests} from "../../../services/firebase-store.service";
import { Question, Test } from "../../../types/Test";

export const TestList = (props: RouteComponentProps) => {
    const [data, setData] = useState<any>([]);
    const { user, setLoading } = useContext(AuthContext);

    useEffect(() => {
        user && getData();
    }, [user])

    const getData = async () => {
        setLoading(true);
        const tests = await getAllTests();
        setData(tests);
        setLoading(false);
    }

    return (
        <div className="p-2">

            <h3>Тести</h3>

            <table className="table table-dark table-striped apll-table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th className="text-center" >ПІБ</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((test: Test, i: number) =>
                            <tr key={i}>
                                <th className="align-top">{i + 1}</th>
                                <td className="align-top">
                                   {test.id}
                                  
                                </td>
                                <td>
                                    <table className="text-center table table-bordered text-white  apll-table">
                                        <th> #</th>
                                        <th> Питання</th>
                                       
                                        <tbody>
                                            {
                                                test.questions.map((q: Question, ii: number) =>
                                                    <tr key={ii}>
                                                        <td>{ii + 1}</td>
                                                        <td>{q.question}
                                                            {
                                                                q.answers.map((ans: string, iii: number) =>
                                                                <div className="s-title" key={iii}>{ans}</div>
                                                                )
                                                            }

                                                        </td>
                                                        
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}

export default withRouter(TestList);