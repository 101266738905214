import './google_slider.css';
import ReactGoogleSlides from "react-google-slides";

const GoogleSlider = ({ width, height, url }: any) => {
   
    return (
        <ReactGoogleSlides
          width={width}
          height={height}
          slidesLink={`${url}`}
          position={1}
          showControls
          loop
          //   slideDuration={5}
        />
      );
}

export default GoogleSlider;