import { useEffect } from "react"
import { NavLink } from "react-router-dom"

export const Page2 = () => {
    useEffect(() => { window.scrollTo(0, 0) }, [])

    return (
        <div className="mx-4  home">
            <h1 className="">Органи управління</h1>
            <p className="">


                Відповідно до Статуту Органами управління Організацією є: Генеральна Асамблея Організації (далі - Асамблея), Правління Організації, Голова Організації.
            </p>
            <p>
                Асамблея є вищим органом Організації, який вправі приймати рішення з будь-яких питань її діяльності, в тому числі і з тих, що передані Асамблеєю до компетенції Правління.
                У Асамблеї беруть участь її члени особисто чи через уповноваженого представника за довіреністю. Кожний член Організації має один голос. Асамблея вважається повноважною, якщо на ній присутні не менше половини членів Організації.
                Чергова Асамблея скликається Правлінням щорічно. Асамблея розглядає питання, винесені на її розгляд Правлінням, Головою Організації, а також членами Організації.
                Позачергова Асамблея скликається при наявності обставин, що зачіпають суттєві інтереси Організації Правлінням Організації, а також в інших випадках, передбачених даним Статутом та законодавством України.
            </p>
            <p>
                Голова Організації –  <NavLink className='nav-link' to={`/user/1`}>КАРПА МАРТА ІВАНІВНА</NavLink>
            </p>
            <p>
                Правління Організації є керівним органом Організації на період між Асамблеями. Правління підзвітне Асамблеї і організовує виконання їх рішень. Правління діє від імені Організації в межах, передбачених даним Статутом, внутрішніми документами та чинним законодавством.
            </p>
            <p>
                Голова Правління – <NavLink className='nav-link' to={`/user/2`}> КІЦАК ТАРАС МИКОЛАЙОВИЧ </NavLink>
            </p>
            <p>

                До компетенції Правління відноситься: <br />
                Організація виконання рішень Асамблеї.
                Скликання Асамблеї та формування їх порядку денного, підготовка матеріалів з питань порядку денного, попередній розгляд всіх питань, що належать до компетенції Асамблеї та підготовка проектів рішень з цих питань до Асамблеї.
                Підготовка та подання рекомендацій Асамблеї щодо визначення основних напрямків діяльності Організації затвердження планів і звітів про їх виконання, інших пропозицій з питань діяльності Організації.
                Затвердження поточних планів діяльності Організації та заходів, необхідних для їх виконання.
                Здійснення окремих функцій щодо управління майном за рішенням Асамблеї членів Організації.
                Підготовка річних звітів з діяльності Організації, в тому числі про залучення і використання коштів і майна Організації; звітів з виконання програм та проектів Організації та подання їх на затвердження Асамблеї.
                Вирішення інших питань, крім тих, що відносяться до виключної компетенції Асамблеї.
                Голова Правління має право: отримувати повну, достовірну та своєчасну інформацію про Організацію, необхідну для виконання своїх функцій; в межах визначених повноважень самостійно та у складі правління вирішувати питання поточної діяльності Організації; вносити пропозиції, брати участь в обговоренні та голосувати з питань порядку денного на засіданні правління Організації; ініціювати скликання засідання Правління Організації; надавати у письмовій формі зауваження на рішення Правління Організації.
                Голова Правління зобов'язаний: діяти в інтересах Організації добросовісно та не перевищувати своїх повноважень; керуватися у своїй діяльності чинним законодавством України, Статутом Організації, іншими внутрішніми документами Організації; виконувати рішення, прийняті Асамблеєю, Правлінням Організації та Головою Організації; особисто брати участь у чергових та позачергових Асамблеях та засіданнях Правління. Завчасно повідомляти про неможливість участі у Асамблеї із зазначенням причини; брати участь у засіданні наглядової ради на її вимогу; дотримуватися всіх встановлених в Організації правил, пов'язаних із режимом обігу, безпеки та збереження інформації з обмеженим доступом; контролювати підготовку і своєчасне надання матеріалів до засідання Правління та Асамблеї; завчасно готуватися до засідання Правління, зокрема, знайомитися з підготовленими до засідання матеріалами, збирати та аналізувати додаткову інформацію, у разі необхідності отримувати консультації фахівців тощо.
                Усі питання, що входять у компетенцію Правління, вирішуються колегіально на засіданнях Правління. Правління проводить чергові і позачергові засідання. Чергові засідання скликаються Головою Організації не менш як раз на 6 (шість) місяців. Про час місце, та порядок денний засідання члени Правління повідомляються за 10 днів до моменту його проведення. Позачергові засідання скликаються Головою Організації за власною ініціативою або на вимогу двох третин членів Правління або за вимогою Голови Правління. Засідання правління є правомочним за умови присутності не менше половини його членів.
                Кожен член Правління може виступати з ініціативою про прийняття рішень із будь-яких питань, що входять до компетенції Правління.
                Рішення Правління приймаються шляхом голосування простою більшістю голосів. Кожен член Правління має один голос. За рівного поділу голосів вирішальним є голос Голови Організації.
                Засідання керівних органів Організації (Асамблеї, Правління), а також інші заходи можуть проходити в різних форматах, як за безпосередньої участі членів, так і за допомогою інтернет зв’язку з використанням аудіовізуальних комп’ютерних програм онлайн конференцій, інших засобів комунікації. Рішення про форму проведення такого/чи іншого формату засідання, формат голосування, формат реєстрації учасників Асамблеї приймає Правління Організації та повідомляє членів Організації не пізніше ніж за 10 днів до визначеної дати проведення засідання (Асамблеї, Правління). Будь-яке засідання керівних органів оформлюється протоколом; про форму засідання обов’язково вказується у протоколі, якщо засідання відбувалось за допомогою інтернет зв’язку, у протоколі обов’язково фіксується за допомогою якої комп’ютерної програми відбувалось засідання.
            </p>
        </div >
    )
}