import { useEffect } from "react"
import './dashboard.css';
import { withRouter } from "react-router-dom";
import { Chart1 } from "../../components/Chart/Chart";
import { Example2 } from "../../components/Chart/Chart3";
// import { Example } from "../../components/Chart/Chart2";


const Dashboard = (props: any) => {
    useEffect(() => { window.scrollTo(0, 0) }, [])

    useEffect(() => {
        document.getElementById('dismiss')?.addEventListener('click', (e: any) => {
            const navbar: any = document.getElementById('sidenav')
            navbar.classList.remove('open');
        })
    }, [])

    return (
        <>
            <Chart1 />
            <Example2 />
        </>
    )
}

export default withRouter(Dashboard);