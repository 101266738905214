import { useContext, useEffect, useRef, useState } from "react";
import { addCoordinatorRequest, getDocs } from "../services/firebase-store.service";
import { AuthContext } from "../core/auth";
import { getDateFormat } from "../helpers/date-format";
import SignatureCanvas from 'react-signature-canvas'
import { NotificationSvc } from "../components/notification/Notification";
import { Status } from "../types/AdnimMenu";
import GoogleSlider from "../components/GoogleSlider/google_slider";

export const Structura = () => {
    const [docs, setDocs] = useState<[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const { user, changeUser, setLoading } = useContext(AuthContext);
    const [downloadInx, setDownloadInx] = useState<number | null>(null)
    const [coordinatorForm, setCoordinatorForm] = useState({ location: '', organization: '', sign: '', userName: '', userUID: '', profileId: '', coordinatorStatus: '' })
    const { onSuccess, onError } = NotificationSvc();
    let signCanvas: any = useRef();

    useEffect(() => { getFiles() }, []);
    useEffect(() => { window.scrollTo(0, 0) }, [])

    const getFiles = async () => {
        setLoading(true);
        const docs: any = await getDocs('docs');
        setDocs(docs)
        setLoading(false);
    }

    const changeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, property: string) => {
        setCoordinatorForm({ ...coordinatorForm, [property]: e.target.value });
    }

    const onSign = () => {
        const storedSign = signCanvas.toDataURL("image/png");
        setCoordinatorForm({ ...coordinatorForm, sign: storedSign });
    }

    const clearSign = () => {
        signCanvas.clear()
        setCoordinatorForm({ ...coordinatorForm, sign: '' });
    }

    const sendForm = async () => {
        if (coordinatorForm.location && coordinatorForm.organization && coordinatorForm.sign) {
            if (user) {
                setCoordinatorForm({ ...coordinatorForm });
                const _u = await addCoordinatorRequest(coordinatorForm, user);
                changeUser(_u);
            }
        } else {
            setIsEmpty(true);
            onError("Усі поля є обов'язковмми", '')
        }
    }

    return (
        <div className="mx-4  home">
            <h1 className="">Структура</h1>
            {
                docs.map((url: any, i: number) =>
                    <div key={i} className="doc-item">
                        <b>   {url.name} </b>
                        {
                            downloadInx === i
                                ? <iframe key={i} src={url.url} />
                                : null
                        }
                        <button onClick={() => setDownloadInx(i)} type="button" className="btn btn-outline-success" data-mdb-ripple-color="dark">
                            Завантажити
                        </button>
                    </div>
                )
            }

            <h5 className="text-center mt-5">Схематичне зображення структури Організації</h5>
            <table className="my-table table table-bordered align-middle">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={6}>
                            <b> Структура управління Організації: </b>
                            <br />  Генеральна Асамблея Організації
                            <br />  Голова Організації
                            <br /> Правління Організації
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-center">
                        <th colSpan={3}> Секретаріат Організації</th>
                        <th colSpan={3}>Центр підвищення рівня професійних компетентностей публічних управлінців </th>
                    </tr>
                    <tr className="text-center" >
                        <th colSpan={6}>
                            <b> Структурні одиниці Організації </b> <br />
                            у відповідності з функціональними та територіальними напрямками діяльності:
                        </th>
                    </tr>

                    <tr >
                        <th>Національний</th>
                        <th>Обласний</th>
                        <th>Районний, місцевий </th>
                        <th>Локальний </th>
                        <td>Рівні</td>
                    </tr>
                    <tr >
                        <th>Центральний комітет</th>
                        <th>Відокремлений підрозділ</th>
                        <th>Місцевий підрозділ </th>
                        <th>Локальний  підрозділ </th>
                        <td>Назви структурних одиниць </td>
                    </tr>
                    <tr >
                        <td>Голова, Перший заступник, Заступники, секретар </td>
                        <td>Керівник, перший заступник,  заступники, секретар</td>
                        <td>Керівник </td>
                        <td>Керівник </td>
                        <td>Керівник </td>
                    </tr>
                    <tr >
                        <td>Відповідно до переліку п.4.2. </td>
                        <td>Відповідно до назви області</td>
                        <td>Відповідно до назви району </td>
                        <td>Відповідно до назви ТГ, інституції, організації, установи </td>
                        <td>Повні (власні) назви  структурних одиниць </td>
                    </tr>
                    <tr >
                        <td>Департаменти по 6 напрямках відповідно до п.4.2.1. (директор)  </td>
                        <td>Департаменти по 6 напрямках відповідно до п.4.2.1. (директор), управління (начальник), відділи (начальник), сектори (завідувач), відділення (завідувач)</td>
                        <td>Відділи (начальник), сектори (завідувач), відділення (завідувач)- на розсуд керівника МП </td>
                        <td>  </td>
                        <td>Підрозділи структурних одиниць(їх керівники) </td>
                    </tr>
                    <tr className="text-center" >
                        <th colSpan={6}>
                            Координатори Організації
                        </th>
                    </tr>
                </tbody>
            </table>


            <div className="pb-5 struktura-form">
                <p className="text-center pt-4">
                    Шановні члени «Національної асоціації публічного управління та адміністрування України»!
                    <br />
                    Запрошуємо Вас стати координаторами «НАЦІОНАЛЬНА АСОЦІАЦІЯ ПУБЛІЧНОГО УПРАВЛІННЯ ТА АДМІНІСТРУВАННЯ УКРАЇНИ»
                </p>

                {
                    user ? user?.coordinatorStatus == Status.pending
                        ? <div className="p-4 color-blue"> Ваша заява кандидата на Координатора в обробці ...  </div>
                        : user?.coordinatorStatus == Status.approve
                            ? <div className="p-4 color-blue"> Вітаємо! Ви стали координатором. Ваш номер - {user?.profileId} </div>
                            : user?.coordinatorStatus == Status.cancel
                                ? <div className="p-4 color-blue"> <i className="fas fa-exclamation-circle red"></i>   Нажаль, Вам відмовлено у статусі координанора </div>
                                : <div className="paper">
                                    <div className="text-right">
                                        Голові правління ГО «НАПУАУ» <br />
                                        Кіцаку Тарасу Миколайовичу
                                        <br />
                                        <br />
                                        {user?.lastName}  {user?.firstName}   {user?.surName}
                                    </div>
                                    <div className="text-center">
                                        Заява №  {user?.profileId}
                                    </div>

                                    <div className="pt-3">
                                        {isEmpty && !coordinatorForm.location && <i className="fas fa-exclamation-circle"></i>}
                                        Територія (яка передбачається для здійснення діяльності Координатора)

                                        <div className="input-group flex-nowrap">
                                            <input
                                                required
                                                type="text"
                                                id="location"
                                                className="form-control"
                                                value={coordinatorForm.location}
                                                onChange={e => changeValue(e, 'location')}
                                                placeholder="Район, село, місто, область"
                                            />
                                        </div>
                                        <div className="subtitle">   (Ці дані Ви можете змінювати відповідно до Ваших можливостей та побажань. Тобто,
                                            вони не є обов’язковими та не обмежують Вас у Вашій діяльності)</div>
                                    </div>

                                    <div className="pt-3">
                                        {isEmpty && !coordinatorForm.organization && <i className="fas fa-exclamation-circle"></i>}
                                        Назва установи(-в)/організації(-й)

                                        <div className="input-group flex-nowrap">
                                            <input
                                                required
                                                type="text"
                                                className="form-control"
                                                placeholder="Назва установи"
                                                id="organization"
                                                value={coordinatorForm.organization}
                                                onChange={e => changeValue(e, 'organization')}
                                            />
                                        </div>
                                        <div className="subtitle"> (Ці дані Ви можете змінювати відповідно до Ваших можливостей та побажань. Тобто,
                                            вони не є обов’язковими та не обмежують Вас у Вашій діяльності)</div>

                                        <p className="pt-3">
                                            Прошу призначити мене Координатором громадської організації
                                            «НАЦІОНАЛЬНА АСОЦІАЦІЯ ПУБЛІЧНОГО УПРАВЛІННЯ ТА АДМІНІСТРУВАННЯ
                                            УКРАЇНИ». З правами координатора ознайомлений.
                                        </p>
                                    </div>

                                    {isEmpty && !coordinatorForm.sign && <i className="fas fa-exclamation-circle"></i>}
                                    Підпис
                                    <div className="sign-main">
                                        <SignatureCanvas penColor='black'
                                            canvasProps={{ className: 'sigCanvas' }}
                                            ref={(ref: any) => { signCanvas = ref }}
                                            onEnd={() => onSign()}
                                        />
                                        <i className="fas fa-times-circle clear" onClick={() => clearSign()}></i>
                                        <br />

                                    </div>
                                    Дата:  {getDateFormat(new Date())}р.


                                    <div className="text-right pt-3">


                                        <button onClick={() => sendForm()} type="button" className="btn btn-outline-primary" data-mdb-ripple-color="dark">
                                            Відправити заявку
                                        </button>
                                    </div>

                                </div>
                        : null
                }
    <div className="text-center">
                <GoogleSlider height={430} width={700} url={'https://docs.google.com/presentation/d/14GCX8QnbqITIu_5EsD8jwIfZywEqMTu65Jllo9tqoNU/edit#slide=id.p1'} />
    </div>

                <h5>ЯК СТАТИ КООРДИНАТОРОМ?</h5>

                <p>
                    Заповніть Заяву. Номер Заяви кандидата на Координатора – це індивідуальний номер
                    Координатора. Запам’ятайте його. Ваш індивідуальний номер Координатора потрібно
                    передати потенційним членам Організації, які вказуватимуть його при реєстрації на сайті
                    Організації у Заяві на вступ до ГО «Національна асоціація публічного управління та
                    адміністрування України». Таким чином, Ваша група членів буде сформована від Вашим
                    індивідуальним номером.
                </p>

                <h5>ЯКІ МОЖЛИВОСТІ КООРДИНАТОРІВ?</h5>

                <p>
                    - Сформувати відповідну закриту групу членів Організації
                    <br /> - Здійснювати комунікаційну, інформаційну та іншу діяльність у межах створеної групи
                    <br /> - Існує можливість створити форуми та інші формати для обміну інформацією у Вашій закритій групі
                    <br /> - Інші можливості обговорюються з координаторами при потребі, відповідно до розміру групи, специфіки тощо.
                </p>

                <h5>ЯКЩО ПРИ РЕЄСТРАЦІЇ ЗАЯВНИК У ЧЛЕНИ НЕ ВКАЗАВ ВАШ НОМЕР КООРДИНАТОРА?</h5>

                <p>
                    Якщо при реєстрації у Заяві на вступ до ГО «Національна асоціація публічного управління та
                    адміністрування України» потенційний член Організації не вказав Ваш номер координатора,
                    координатор звертається до адміністрації Організації з відповідним проханням, у якому
                    вказує ПІП члена організації, його контактні дані, організацію та свій номер Координатора.
                    Після перевірки даних члену групи буде присвоєно код/номер Координатора.
                </p>

                <h5 className="text-center pt-3">
                    <b>  ВИТЯГ з ПОЛОЖЕННЯ ПРО СТРУКТУРУ
                        ГРОМАДСЬКОЇ ОРГАНІЗАЦІЇ <br /> «НАЦІОНАЛЬНА АСОЦІАЦІЯ ПУБЛІЧНОГО УПРАВЛІННЯ ТА АДМІНІСТРУВАННЯ УКРАЇНИ»
                    </b>
                </h5>

                <p>
                    4.4. Координатор Організації – член Організації, діяльність якого направлена на
                    виконання статуту Організації у межах діяльності окремих членів Організації, їх
                    об’єднань у групи, незалежно від територіального чинника чи структурного
                    підрозділу Організації.
                    <br />  4.4.1. Координатор має право налагоджувати комунікаційні зв’язки членів Організації,
                    вести інформаційну підтримку членів Організації, координувати діяльність
                    Організації у межах закріплених за ним членів Організації.
                    <br />  4.4.2. Організаційні заходи, інформаційні заходи та інші заходи, які здійснює
                    координатор мають відбуватися за погодженням та з інформуванням відповідної
                    структурної одиниці Організації за територіальним критерієм, а саме ЛП, МП чи
                    ВП. Організаційні заходи, інформаційні заходи та інші заходи, які здійснює
                    координатор на національному рівні, з залученням двох та більше областей мають
                    відбуватися за погодженням та з інформуванням Правління організації.
                    <br /> 4.4.3. Статус координатора організації може бути наданий будь-якому члену
                    Організації, в тому числі керівному складу структурних одиниць, за умови
                    формування груп членів Організації.
                    <br />  4.4.4. Кандидати на Координаторів звертаються з відповідною заявою, у якій вказано
                    локацію діяльності, назву установи(-в)/організацій (ї), їх адреси, свої контакті дані
                    до Правління Організації. Призначення координаторів відбувається після
                    співбесіди з ними та відповідного призначення Правлінням Організації.
                    <br />  4.4.5. Кожному координатору присвоюється індивідуальний код/номер, за яким
                    проходить його ідентифікація на веб-порталі Організації.
                </p>



            </div>
        </div >
    )
}