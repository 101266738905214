import { useContext, useEffect, useRef, useState } from 'react';
import './registration-form.css';
import { AuthContext, createUser } from '../../core/auth';
import { User } from '../../types/auth';
import { getRegions } from '../../services/local-data/regions';
import { getComitetes } from '../../services/local-data/comitets';
import { Comitet } from '../../types/Comitets';
import { getDocs, updateUser } from '../../services/firebase-store.service';
import { NavLink } from 'react-router-dom';
import { PaymentKurs } from '../../components/payments/payment_kurs';
import SignatureCanvas from 'react-signature-canvas'
import { NotificationSvc } from '../../components/notification/Notification';

export const RegistrationForm = () => {
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const { user, changeUser, setLoading } = useContext(AuthContext);
    const [_user, setUser] = useState<User>(user as User);
    const regions = getRegions();
    const comitets = getComitetes();
    const [docs, setDocs] = useState<[]>([]);
    const [downloadInx, setDownloadInx] = useState<number | null>(null)
    const { onSuccess, onError } = NotificationSvc();
    let signCanvas: any = useRef();

    useEffect(() => {
        setUser(user as User)
        if (user && user?.sign) {
            signCanvas.fromDataURL(user.sign)
        }
    }, [user]);
    useEffect(() => { window.scrollTo(0, 0) }, [])
    useEffect(() => { getFiles() }, []);

    useEffect(() => {
        const forms = document.querySelectorAll('.needs-validation');
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event: any) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }
                    form.classList.add('was-validated')
                }, false)
            })
    }, []);

    const getFiles = async () => {
        const a: any = await getDocs('docs/public');
        setDocs(a)
    }

    const onSign = () => {
        const storedSign = signCanvas.toDataURL("image/png");
        setUser({ ..._user, sign: storedSign } as User);
    }

    const clearSign = () => {
        signCanvas.clear()
        setUser({ ..._user, sign: '' } as User);
    }

    const changeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, property: string) => {
        setUser({ ..._user, [property]: e.target.value } as User);
    }

    const register = (e: React.FormEvent<HTMLFormElement>) => {

        e?.preventDefault();
        if (_user?.sign) {
            const _promise = _user && user?.uid
                ? updateUser(_user?.uid, _user)
                : createUser(_user, _user.email || '', _user.password)
            // createUser(_user, _user.email || '', _user.password)
            _promise.then(_user => {
                if (_user) {
                    _user && changeUser(_user as User);
                    setLoading(true);
                    setUser(_user as User);
                    setTimeout(() => {
                        const a = document.getElementById('Pay-registration');
                        a && a.click();
                        setLoading(false);
                    }, 1000);
                }
            })
        } else {
            // onError('')
            onSuccess('Поставте підпис, будь ласка!')
        }
    }

    const changeType = () => {

    }

    return (
        <div className='reg-tab'>
            <div className='text-right'>  Голові правління ГО «НАПУАУ» <br /> Кіцаку Тарасу Миколайовичу </div>

            <div className='text-center font-b mt-4 pt-4 pb-4'>  Заява на вступ до ГО «Національна асоціація <br /> публічного управління та адміністрування України»  </div>
            {/* ______________form */}
            <form className='needs-validation' onSubmit={(e) => register(e)} noValidate>
                <div className=" mb-4">
                    <label className="form-label" htmlFor="lastName">Прізвище</label>
                    <input required
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={_user?.lastName || ''}
                        onChange={e => changeValue(e, 'lastName')}
                    />
                    <div className="invalid-feedback">The field is required.</div>
                </div>

                <div className=" mb-4">
                    <label className="form-label" htmlFor="firstName">Ім'я</label>
                    <input required
                        type="text"
                        id="firstName"
                        className="form-control"
                        value={_user?.firstName || ''}
                        onChange={e => changeValue(e, 'firstName')}
                    />
                    <div className="invalid-feedback">The field is required.</div>
                </div>
                <div className=" mb-4">
                    <label className="form-label" htmlFor="surName">По Батькові</label>
                    <input required
                        type="text"
                        id="surName"
                        className="form-control"
                        value={_user?.surName || ''}
                        onChange={e => changeValue(e, 'surName')}
                    />
                    <div className="invalid-feedback">The field is required.</div>
                </div>
                <div className=" mb-4">
                    <label className="form-label" htmlFor="dateOfBirth">Дата народження</label>
                    <input required
                        type="date"
                        id="dateOfBirth"
                        className="form-control"
                        value={_user?.dateOfBirth || ''}
                        onChange={e => changeValue(e, 'dateOfBirth')}
                    />
                    <div className="invalid-feedback">The field is required.</div>
                </div>

                <div className=" mb-4">
                    <label className="form-label" htmlFor="email">Пошта</label>
                    <input required
                        value={_user?.email || ""}
                        onChange={e => changeValue(e, 'email')}
                        type="email"
                        id="email"
                        className="form-control"
                    />
                    <div className="invalid-feedback">The field is required.</div>
                </div>

                <div className=" mb-4 select-gr">

                    {/* <input readOnly required defaultValue={_user?.region || null || ''} type="text" className="form-control" /> */}
                    <label className="form-label" htmlFor="region" >Область</label>
                    <select
                        required
                        id="region"
                        className="form-select"
                        onChange={e => changeValue(e, 'region')} value={_user?.region || ''} >
                        <option hidden value={''} ></option>
                        {
                            regions.map((el: any, i: number) => <option key={i} value={el.name}>{el.name}</option>)
                        }
                    </select>
                    <div className="invalid-feedback">The field is required.</div>
                </div>

                <div className=" mb-4">
                    <label className="form-label" htmlFor="workPlace">Місце роботи</label>
                    <input required
                        type="text"
                        id="workPlace"
                        className="form-control"
                        value={_user?.workPlace || ''}
                        onChange={e => changeValue(e, 'workPlace')}
                    />
                    <div className="invalid-feedback">The field is required.</div>
                </div>
                <div className=" mb-4">
                    <label className="form-label" htmlFor="position">Посада</label>
                    <input required
                        type="text"
                        id="position"
                        className="form-control"
                        value={_user?.position || ''}
                        onChange={e => changeValue(e, 'position')}
                    />
                    <div className="invalid-feedback">The field is required.</div>
                </div>

                <div className=" mb-4 select-gr">
                    <label className="form-label" htmlFor="centralComm" >Центральний комітет ГО</label>
                    <select
                        required
                        id="centralComm"
                        className="form-select"
                        onChange={e => changeValue(e, 'centralComm')}
                        value={_user?.centralComm || ''} >
                        <option hidden value={''} ></option>
                        {
                            comitets.map((comitet: Comitet, i: number) =>
                                <option key={i} value={comitet.value}>{comitet.name}</option>
                            )
                        }
                    </select>
                    <div className="invalid-feedback">The field is required.</div>
                </div>

                <div className=" mb-4">
                    <label className="form-label" htmlFor="coordinatorNumber">Номер координатора</label>
                    <input
                        type="text"
                        id="coordinatorNumber"
                        className="form-control"
                        value={_user?.coordinatorNumber || ''}
                        onChange={e => changeValue(e, 'coordinatorNumber')}
                    />
                </div>

                <div className=" mb-4">
                    <label className="form-label" htmlFor="password" >Пароль</label>
                    <input required
                        value={_user?.password}
                        onChange={e => changeValue(e, 'password')}
                        type="password"
                        id="password"
                        minLength={6}
                        className="form-control"
                    />
                    <div className="invalid-feedback">The field is required.</div>
                </div>

                <div className=" mb-4">
                    <label className="form-label" htmlFor="registerRepeatPassword">Повторно пароль</label>
                    <input required
                        pattern={_user?.password}
                        type="password"
                        minLength={6}
                        className="form-control"
                        id="registerRepeatPassword"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <div className="invalid-feedback">Must match the previous entry.</div>
                </div>

                <div className='reg-text pt-5'>
                    Прошу прийняти мене в члени громадської організації «Національна асоціація публічного управління та адміністрування України».
                    Зі змістом Статуту, Публічним Договором вступу до ГО «НАПУАУ» та внутрішніми нормативними положеннями організації ознайомлений/на і зобов’язуюсь їх виконувати. Підтверджую, що вся надана мною інформація є достовірною. Надаю згоду Громадській організації «Національна асоціація публічного управління та адміністрування України» після прийому мене в члени організації на використання даної інформації відповідно до мети та завдань організації, викладених у Статуті. Відповідно до Закону України «Про захист персональних даних» даю згоду на обробку моїх персональних даних та даних організації, яку представляю, з первинних джерел; використання персональних даних, що передбачає дії
                    володільця бази щодо обробки цих даних, в тому числі використання
                    персональних даних відповідно до їх професійних чи службових або трудових
                    обов’язків, дії щодо їх захисту, а також дії щодо надання часткового або
                    повного права обробки персональних даних іншим суб’єктам відносин,
                    пов’язаних із персональними даними (стаття 10 зазначеного Закону);
                    поширення персональних даних, що передбачає дії володільця бази
                    персональних даних щодо передачі відомостей про фізичну особу з бази
                    персональних даних (стаття 14 зазначеного Закону); доступ до персональних
                    даних третіх осіб, що визначає дії володільця бази персональних даних у разі
                    отримання запиту від третьої особи щодо доступу до персональних даних, у
                    тому числі порядок доступу суб’єкта персональних даних до відомостей про
                    себе (стаття 16 зазначеного Закону). При поданні особистих даних у Заяві на
                    вступ до ГО «Національна асоціація публічного управління та адміністрування
                    України», поданні даних при заповненні особистого профілю, поданні іншої
                    інформації на веб-порталі Організації <NavLink to='/'> www.unapam.com </NavLink> , даю згоду на
                    розповсюдження/розголошення такої інформації у публічний простір. За надання
                    чи/та оприлюднення недостовірної інформації, порушення інформаційного
                    законодавства тощо несу відповідальність згідно чинного законодавства України.
                </div>

                <div className='mt-4'>
                    {
                        docs.map((url: any, i: number) =>
                            <div key={i} className="doc-item mt-1">
                                <b> {url.name} </b>
                                {
                                    downloadInx === i
                                        ? <iframe key={i} src={url.url} />
                                        : null
                                }
                                <button onClick={() => setDownloadInx(i)} type="button" className="btn btn-outline-success" data-mdb-ripple-color="dark">
                                    Завантажити
                                </button>
                            </div>



                        )
                    }

                </div>

                <div className='mt-3'>
                    Я обираю:
                </div>
                <div className='display-f pt-2'>
                    <input
                        required
                        className="form-check-input me-2"
                        type="checkbox"
                        value="categoryType"
                        id="registerCheck"
                        checked={true}
                        onChange={() => () => changeType()}
                        aria-describedby="registerCheckHelpText"
                    />
                    <label className="form-check-label" htmlFor="registerCheck">
                        Вартість членського внеску у рік становить 700,00 грн.
                    </label>
                    <div className="invalid-feedback">This checkbox is required.</div>
                </div>
                <div className='display-f pt-2'>
                    <input
                        disabled
                        required
                        className="form-check-input me-2"
                        type="checkbox"
                        value="categoryType"
                        id="registerCheck2"
                        checked={false}
                        onChange={() => () => null}
                        aria-describedby="registerCheckHelpText"
                    />
                    <label className="form-check-label" htmlFor="registerCheck2">
                        Вартість членського внеску у рік становить 800 грн.
                    </label>
                    <div className="invalid-feedback">This checkbox is required.</div>
                </div>

                <div className='pt-3'>
                    Підпис
                    <div className="sign-main">
                        <SignatureCanvas penColor='black'
                            canvasProps={{ className: 'sigCanvas' }}
                            ref={(ref: any) => { signCanvas = ref }}
                            onEnd={() => onSign()}
                        />
                        <i className="fas fa-times-circle clear" onClick={() => clearSign()}></i>
                        <br />
                    </div>
                </div>

                <button type="submit" className="btn btn-primary btn-block mb-3 mt-5">Реєстрація</button>
            </form>

            <PaymentKurs kyrs={null} type={'registration'} hidden={true} />
        </div>


    )

}