import { useContext, useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { DataService } from "../services/firebase.service";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../core/auth";
import { updateUser } from "../services/firebase-store.service";

export const Payment = (props: RouteComponentProps) => {
    const { user, setLoading } = useContext(AuthContext);
    const [payId, setPayId] = useState<string | null>(null);

    const { paid } = DataService();
    const history = useHistory();

    useEffect(() => { window.scrollTo(0, 0) }, [])

    useEffect(() => {
        const params: any = props.match.params;
        if (params && params.id) {
            setPayId(params.id);
            test(params.id)
        }
    }, [user]);


    const test = async (payId: string) => {
        if (payId && user) {
            const id = payId?.split('-')
            setLoading(true);
            const res = await paid(payId, user);
            if (res) {
                if (!res.response.error) {
                    const _user = Object.assign({}, user);
                    if (!_user?.payments) {
                        _user.payments = [];
                    }
                    _user.payments.push(res.response[0])
                    await updateUser(user?.uid, _user);
                    setLoading(false);
                    if (id[1] == 'registration' || id[1] == 'membership') {
                        window.location.replace(`/`);
                    } else {
                        window.location.replace(`/kyrs/${id[1]}`);
                    }
                } else {
                    setLoading(false);
                    if (id[1] == 'registration' || id[1] == 'membership') {
                        window.location.replace(`/`);
                    } else {
                        window.location.replace(`/kyrs/${id[1]}`);
                    }
                }
            } else {
                setLoading(false);
            }
        }

    }



    return (
        <div className="mx-4  home">
            <h1 className="">Awaiting...</h1>
        </div >
    )
}