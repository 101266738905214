import { User } from "../types/auth";
import moment from 'moment';
moment().format();

export const isPayMember = (user: User): boolean => {
    const pay = user.payments.find((r: any) => r?.shopOrderNumber == `${user.uid}-registration`)
    if (pay && pay.status == 'PAYED') {
        const a = moment(pay.pay_date).add(1, 'years').toString()
        const b = moment().toString()
        if (new Date(a) > new Date(b)) {
            return true;
        } else {
            const payM = user.payments.filter((r: any) => r?.shopOrderNumber == `${user.uid}-membership`)
            if (payM && payM.length > 0) {
                payM.sort((a: any, b: any) => new Date(a.pay_date) > new Date(b.pay_date) ? -1 : 1)
                const aa = moment(payM[0].pay_date).add(1, 'years').toString()
                if (new Date(aa) > new Date(b)) {
                    return true;
                }
            }
            return false;
        }
    } else {
        return false;
    }
}