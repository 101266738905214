import { useContext, useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AuthContext } from "../../../core/auth";
import { getDateFormat } from "../../../helpers/date-format";
import { getSendedEmail } from "../../../services/firebase-admin.service";

export const SendedEmail = (props: RouteComponentProps) => {
    const [data, setData] = useState<[]>([]);
    const { user, setLoading } = useContext(AuthContext);

    useEffect(() => { window.scrollTo(0, 0) }, []);

    useEffect(() => {
        user && getData();
    }, [user])


    const getData = async () => {
        setLoading(true);
        const _data = await getSendedEmail()
        setLoading(false);
        setData(_data);
    }


    return (
        <div className="p-2">
            <h3>Надіслані повідомлення</h3>

            <table style={{fontSize: '12px'}} className="table table-dark table-striped apll-table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Кому</th>
                        <th scope="col">Тема</th>
                        <th scope="col">Повідомлення</th>
                        <th scope="col">Дата</th>

                    </tr>
                </thead>
                <tbody>
                    {data.map((message: any, i: number) =>
                        <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{message.emails.replaceAll(",", ", ")}</td>
                            <td>{message.theme}</td>
                            <td>{message.message}</td>
                            <td>{getDateFormat(message.date)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default withRouter(SendedEmail);