import { UseFetch } from "../hooks/useFetch";

export const DataService = () => {

    // const baseUrl = "http://127.0.0.1:5001/web-unapam/us-central1/api";
    const baseUrl = "https://us-central1-web-unapam.cloudfunctions.net/api";


    const paid = async (payId: string, user: any) => {
        const { makeRequest } = UseFetch(`${baseUrl}/paid`);
        try {
            const res = await makeRequest({ method: "POST", body: { payId, user } });
            return res;
        } catch (err) {

        }
    }
    const sendEmail = async (emailTo: string, emailBcc: string, title: string, name: string, email: string, theme: string, text: string, isLawTemplate: boolean = true) => {
        const { _makeRequest } = UseFetch(`${baseUrl}/sendmail`);
        return _makeRequest({ method: "POST", body: { emailTo, emailBcc, title, name, email, theme, text } })
            .then(response => response.json());
    }

    const resultTest = async (testId: string, answers: any) => {
        const { _makeRequest } = UseFetch(`${baseUrl}/testresult`);
        return _makeRequest({ method: "POST", body: { testId, answers } })
            .then(response => response.json())
    }

    return { paid, resultTest, sendEmail }
}
